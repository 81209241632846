import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Tooltip, Select, Checkbox } from "antd";
import CohortSelector from "./ExecutiveOverview/CohortSection/CohortSelector";
import { getBoardPayStructure } from "../redux/actions/CompanyAction";
import { BoardStructureSummaryStatTable } from "./BoardPayStructure/BoardStructureSummaryStatTable";
import { emdash, toDollar } from "../utils/Constants/systemSetting";
import { exportToCSV } from "../utils/ExecutiveData/csvExport";
import slugifyName from "../utils/slugifyName";

import boardPayPerkMap from "../assets/json/boardStructurePerkMap.json";
import boardStructurePerksAndFrequency from "../assets/json/boardStructurePerksAndFrequency.json";

// // Potential CSV export
// const formatDataForCSVexport = useCallback(() => {
//   const boardPayStructureOutputData = [];
//   if (boardPayStructureData?.length) {
//     boardPayStructureOutputData.push(
//       ...boardPayStructureData?.map((item) => {
//         return { ...item };
//       })
//     );
//   }
//   const boardPayStructureHeaders = [];
//   boardPayStructureColumns.forEach((item) => {
//     if (!boardPayStructureHeaders.includes(item.dataIndex)) {
//       boardPayStructureHeaders.push(item.dataIndex);
//     }
//   });
//   const formattedBoardPayData = boardPayStructureOutputData.map((item) => {
//     const formattedItem = {};
//     boardPayStructureColumns.forEach((keyDetails, index) => {
//       if (keyDetails?.dataIndex) {
//         formattedItem[
//           index === 0
//             ? `"${companyInfo?.Company} Executive Name"`
//             : `"${keyDetails.title.replaceAll("#", "Number of Shares")}"`
//         ] =
//           item?.[keyDetails.dataIndex] &&
//           (item?.[keyDetails?.dataIndex].length > 2 ||
//             item?.[keyDetails?.dataIndex] >= 0)
//             ? item[keyDetails?.dataIndex].replaceAll(",", "")
//             : "";
//       }
//     });
//     return formattedItem;
//   });
//   return formattedBoardPayData;
// }, [companyInfo, boardPayStructureData, boardPayStructureColumns]);

//TODO: Fix sorter issues - remove blank column with boardmembertype "0" or allow error handling

export default function BoardPayStructure({ companyInfo, PDFprocessing }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.UserReducer);
  const { subscription } = useSelector((state) => state.CustomerReducer);
  const isTier2 = useMemo(() => {
    if (user?.tier2access || subscription?.plan?.metadata?.isTier2 === "true") {
      return true;
    }
    return false;
  }, [user, subscription]);

  function addSpaceBetweenCapitalizedWords(str) {
    return str.replace(/([a-z])([A-Z])/g, "$1 $2");
  }
  function toTitleCase(str) {
    return str.replace(
      /\w\S*/g,
      (text) => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
    );
  }

  const { boardPayStructure } = useSelector((state) => state.CompanyReducer);

  const [cohortTableTickers, setCohortTableTickers] = useState([]);
  const [cohortSelected, setCohortSelected] = useState("None");
  const [boardMemberTypesSelected, setBoardMemberTypesSelected] = useState([
    "BasicBoardMember",
  ]);

  const allBoardMemberTypes = [
    { label: "Basic Board Member", value: "BasicBoardMember" },
    { label: "Lead Directors", value: "boardStructureSpecials" },
    { label: "Board Chairs", value: "boardStructureChairs" },
    { label: "Board Non-Chairs", value: "boardStructureNonChairs" },
  ];

  function onCheckboxChange(checkedValues) {
    setBoardMemberTypesSelected(checkedValues);
  }

  useMemo(() => {
    const token = localStorage.getItem("ACCESS_TOKEN");
    const ticker = companyInfo?.Ticker;
    if (cohortTableTickers.length) {
      dispatch(getBoardPayStructure([ticker, ...cohortTableTickers], token));
    } else {
      dispatch(getBoardPayStructure([ticker], token));
    }
  }, [companyInfo, cohortTableTickers, dispatch]);

  const getCompanyBoardPayStructure = useCallback(
    (ticker) => {
      if (
        !boardPayStructure ||
        !boardPayStructure?.[ticker]?.BoardCompensationStructure
      ) {
        return undefined;
      }
      return boardPayStructure[ticker].BoardCompensationStructure;
    },
    [boardPayStructure]
  );

  const companyBoardPayStructure = useMemo(() => {
    const companyBoardPayStructure = getCompanyBoardPayStructure(
      companyInfo?.Ticker
    );
    if (companyBoardPayStructure) {
      return {
        Ticker: companyInfo.Ticker,
        companyName: boardPayStructure?.[companyInfo?.Ticker]?.CompanyName,
        ...getCompanyBoardPayStructure(companyInfo?.Ticker),
      };
    }
  }, [getCompanyBoardPayStructure, companyInfo, boardPayStructure]);

  const peerGroupBoardPayStructure = useMemo(() => {
    const peerGroupBoardPayStrucure = [];
    for (const ticker of cohortTableTickers) {
      const companyBoardPayStructureForPeerCompany =
        getCompanyBoardPayStructure(ticker);
      if (companyBoardPayStructureForPeerCompany && ticker) {
        peerGroupBoardPayStrucure.push({
          Ticker: ticker,
          companyName: boardPayStructure?.[ticker]?.CompanyName || "",
          ...companyBoardPayStructureForPeerCompany,
        });
      }
    }
    return peerGroupBoardPayStrucure;
  }, [getCompanyBoardPayStructure, boardPayStructure, cohortTableTickers]);

  const getCombinedBoardStructure = useCallback(
    (allBoardPayStructures, isPeerGroupTable = false) => {
      if (!allBoardPayStructures || !allBoardPayStructures.length)
        return undefined;
      const allCombinedBoardStructure = allBoardPayStructures.map(
        (companyBoardPayStructure) => {
          if (!companyBoardPayStructure) return undefined;
          const boardStructureChairs = companyBoardPayStructure?.Chairs
            ? Object.entries(companyBoardPayStructure.Chairs).map(
                ([comitteeKey, comitteePayStructure]) => {
                  const newRow = {};
                  newRow[`${comitteeKey} Chair`] = comitteePayStructure;
                  return newRow;
                }
              )
            : [];

          const boardStructureNonChairs = companyBoardPayStructure?.NonChairs
            ? Object.entries(companyBoardPayStructure.NonChairs).map(
                ([comitteeKey, comitteePayStructure]) => {
                  const newRow = {};
                  newRow[`${comitteeKey} Non‑Chair`] = comitteePayStructure;
                  return newRow;
                }
              )
            : [];

          const commonBoardStructureTitles = [
            "BasicBoardMember",
            "Chairs",
            "NonChairs",
            "OtherPerks",
            "AdditionalDetails",
            "Citation",
            "Ticker",
            "companyName",
            "ExplanationOfFailure",
          ];

          const specialBoardMemberKeys = Object.keys(
            companyBoardPayStructure
          ).filter((item) => !commonBoardStructureTitles.includes(item));

          const boardStructureSpecials = specialBoardMemberKeys.length
            ? specialBoardMemberKeys.map((specialBoardMemberKey) => {
                const newRow = {};
                newRow[`${specialBoardMemberKey}`] =
                  companyBoardPayStructure[specialBoardMemberKey];
                return newRow;
              })
            : [];

          const combinedBoardStructure = [];
          if (
            !isPeerGroupTable ||
            boardMemberTypesSelected.includes("BasicBoardMember")
          ) {
            combinedBoardStructure.push({
              "Basic Board Member": companyBoardPayStructure?.BasicBoardMember,
            });
          }

          if (
            !isPeerGroupTable ||
            boardMemberTypesSelected.includes("boardStructureSpecials")
          ) {
            combinedBoardStructure.push(...boardStructureSpecials);
          }

          if (
            !isPeerGroupTable ||
            boardMemberTypesSelected.includes("boardStructureChairs")
          ) {
            combinedBoardStructure.push(...boardStructureChairs);
          }
          if (
            !isPeerGroupTable ||
            boardMemberTypesSelected.includes("boardStructureNonChairs")
          ) {
            combinedBoardStructure.push(...boardStructureNonChairs);
          }

          // const combinedBoardStructure = [
          //   { "Basic Board Member": companyBoardPayStructure?.BasicBoardMember },
          //   ...boardStructureSpecials,
          //   ...boardStructureChairs,
          //   ...boardStructureNonChairs,
          // ];

          const recombinedBoardStructureMap = combinedBoardStructure.map(
            (item) =>
              Object.entries(item).map(([key, val]) => {
                if (
                  typeof val === "object" &&
                  key &&
                  companyBoardPayStructure?.["Ticker"]
                ) {
                  val["Ticker"] = companyBoardPayStructure["Ticker"];
                  val["CompanyName"] = companyBoardPayStructure["companyName"];
                  val["boardMemberType"] =
                    key +
                    (val?.AdditionalDetails &&
                    companyBoardPayStructure?.["Ticker"] !== companyInfo?.Ticker
                      ? " *"
                      : "");
                  return val;
                } else {
                  return undefined;
                }
              })
          );
          return recombinedBoardStructureMap.flat();
        }
      );
      return [...allCombinedBoardStructure];
    },
    [companyInfo, boardMemberTypesSelected]
  );

  // useEffect(() => {
  //   const token = localStorage.getItem("ACCESS_TOKEN");
  //   const ticker = companyInfo?.Ticker;
  //   if (ticker) {
  //     dispatch(getBoardPayStructure([ticker], token));
  //   }
  // }, [companyInfo, dispatch]);

  const combinedBoardStructure = getCombinedBoardStructure([
    companyBoardPayStructure,
  ]);

  const combinedPeerGroupBoardStructure = getCombinedBoardStructure(
    peerGroupBoardPayStructure,
    true
  );

  const getBoardPayStructureColumns = useCallback(
    (combinedBoardStructure, isPeerGroupTable = false) => {
      if (!combinedBoardStructure) return [];

      const boardStructureColumns = [
        {
          title: "Ticker",
          dataIndex: "Ticker",
          key: "Ticker",
          align: "center",
          responsive: !isPeerGroupTable ? [] : null,
          sorter: (a, b) => a.Ticker.localeCompare(b.Ticker),
        },
        {
          title: "Company Name",
          dataIndex: "CompanyName",
          key: "CompanyName",
          align: "center",
          responsive: !isPeerGroupTable ? [] : null,
          sorter: (a, b) => a.CompanyName.localeCompare(b.CompanyName),
        },
        {
          title: "Board Member Type",
          dataIndex: "boardMemberType",
          key: "boardMemberType",
          align: "center",
          sorter: (a, b) => a.boardMemberType.localeCompare(b.boardMemberType),
        },
      ];

      combinedBoardStructure.forEach((boardStructure) => {
        if (typeof boardStructure !== "object") return;
        Object.entries(boardStructure).forEach(
          ([committeeKey, committeePayStructure]) => {
            if (typeof committeePayStructure !== "object") return;
            Object.entries(committeePayStructure).forEach(
              ([payKey, payValue]) => {
                if (
                  !boardStructureColumns.some((item) => item.key === payKey)
                ) {
                  boardStructureColumns.push({
                    title: !["RSUs", "PSUs"].includes(payKey)
                      ? toTitleCase(addSpaceBetweenCapitalizedWords(payKey))
                      : payKey,
                    //   +
                    // (!["AdditionalDetails"].includes(payKey) ? " ($)" : ""),
                    dataIndex: payKey,
                    key: payKey,
                    align: "center",
                    responsive: ["AdditionalDetails"].includes(payKey)
                      ? []
                      : null,
                    render: (val, rowData) =>
                      ["AdditionalDetails"].includes(payKey) ||
                      (val && val.toString().includes("%")) ? (
                        <b>{val || emdash}</b>
                      ) : (
                        toDollar(val) || emdash
                      ),
                    sorter: (a, b) => {
                      // Remove non-numeric characters except for periods and commas

                      if (!a[payKey]) return 1;
                      if (!b[payKey]) return -1;

                      const numA = parseFloat(
                        a[payKey].toString().replace(/[^0-9.-]+/g, "")
                      );
                      const numB = parseFloat(
                        b[payKey].toString().replace(/[^0-9.-]+/g, "")
                      );

                      // Handle NaN (non-numeric values)
                      if (isNaN(a[payKey])) return 1;
                      if (isNaN(b[payKey])) return -1;

                      return numA - numB;
                    },
                  });
                }
              }
            );
          }
        );
      });
      boardStructureColumns.push({
        title: "Total",
        dataIndex: "total",
        key: "total",
        align: "center",
        render: (val, rowData) =>
          val && val.toString().includes("%") ? (
            <b>{val || emdash}</b>
          ) : (
            toDollar(val) || emdash
          ),
        sorter: (a, b) => {
          // Remove non-numeric characters except for periods and commas
          const numA = parseFloat(a.total.toString().replace(/[^0-9.-]+/g, ""));
          const numB = parseFloat(b.total.toString().replace(/[^0-9.-]+/g, ""));

          // Handle NaN (non-numeric values)
          if (isNaN(numA)) return 1;
          if (isNaN(numB)) return -1;

          return numA - numB;
        },
      });
      const sortedColumns = boardStructureColumns.sort((a, b) => {
        if (a.dataIndex === "AdditionalDetails") return 1;
        if (b.dataIndex === "AdditionalDetails") return -1;
        return 0;
      });
      return sortedColumns;
    },
    []
  );

  const getBoardPayStructureData = useCallback(
    (combinedBoardStructure, isPeerGroupTable = false) => {
      if (!combinedBoardStructure) {
        return [];
      }
      const allBoardPayStructureData = [];
      const allBoardPayOverView = {};
      let peerGroupCompanies = 0;
      combinedBoardStructure.forEach((boardStructure, index1) => {
        if (typeof boardStructure !== "object") return;
        Object.entries(boardStructure).forEach(
          ([committeeKey, committeePayStructure], index2) => {
            if (!committeeKey || !committeePayStructure) return;
            peerGroupCompanies += 1;
            Object.entries(committeePayStructure).forEach(([key, val]) => {
              if (val && val > 0) {
                if (!allBoardPayOverView?.[key]) {
                  allBoardPayOverView[key] = 1;
                } else {
                  allBoardPayOverView[key] += 1;
                }
              }
            });
            allBoardPayStructureData.push({
              key: `${committeeKey}-${index1}-${index2}`, // key should be auto-assinged to ensure it is unique
              boardMemberType: toTitleCase(
                addSpaceBetweenCapitalizedWords(committeeKey)
              ),
              ...committeePayStructure,
              total:
                typeof committeePayStructure !== "object"
                  ? emdash
                  : Object.values(committeePayStructure).reduce(
                      (prev, curr) =>
                        typeof curr === "number" ? prev + curr : prev,
                      0
                    ),
            });
          }
        );
      });
      const overviewRow = {
        Ticker: (
          <div style={{ cursor: "help" }}>
            <Tooltip
              title={
                "Percent of board members in this table that have this compensation type."
              }
            >
              <b>Percent</b>
            </Tooltip>
          </div>
        ),
        CompanyName: emdash,
        boardMemberType: emdash,
        total: "100.0%",
      };

      Object.entries(allBoardPayOverView).forEach(([key, value]) => {
        overviewRow[key] =
          (100 * (value / peerGroupCompanies)).toFixed(1) + "%";
      });
      if (isPeerGroupTable) {
        allBoardPayStructureData.unshift(overviewRow);
      }
      return allBoardPayStructureData.filter((item) => item?.Ticker);
    },
    []
  );

  const getOtherPerksColumns = useMemo(() => {
    if (!boardPayStructure) return [];
    const allPerksColumns = [
      {
        // title: "Ticker",
        title: <span style={{ fontSize: "10.5px" }}>Ticker</span>,
        dataIndex: "Ticker",
        key: "Ticker",
        align: "center",
        sorter: (a, b) => a.Ticker.localeCompare(b.Ticker),
        render: (text) => <span style={{ fontSize: "10.5px" }}>{text}</span>,
        width: 20,
      },
      // {
      //   title: "Company Name",
      //   dataIndex: "CompanyName",
      //   key: "CompanyName",
      //   align: "center",
      //   sorter: (a, b) => a.CompanyName.localeCompare(b.CompanyName),
      //   render: (text) => <span style={{ fontSize: "10.5px" }}>{text}</span>,
      // },
    ];
    for (const [key, values] of Object.entries(boardPayStructure)) {
      const OtherPerks = values?.BoardCompensationStructure?.OtherPerks?.Perks;
      if (!OtherPerks || !OtherPerks.length) continue;
      for (const perk of OtherPerks) {
        const titleCasePerk = toTitleCase(perk);
        const formattedPerk = boardPayPerkMap?.[perk] || "";
        if (
          formattedPerk &&
          !allPerksColumns.some((item) => item.key === formattedPerk)
        ) {
          allPerksColumns.push({
            title: <span style={{ fontSize: "10.5px" }}>{formattedPerk}</span>,
            dataIndex: formattedPerk,
            key: formattedPerk,
            align: "center",
            render: (text) => (
              <span style={{ fontSize: "10.5px" }}>{text}</span>
            ),
            width: 20,
          });
        }
      }
    }
    return allPerksColumns;
  }, [boardPayStructure]);

  const getOtherPerksData = useMemo(() => {
    if (!boardPayStructure) return [];
    const allPerksData = [];
    const perkOverview = {};
    for (const [key, values] of Object.entries(boardPayStructure)) {
      const newDataRow = {
        Ticker: values?.Ticker,
        CompanyName: values?.CompanyName,
      };
      const OtherPerks = values?.BoardCompensationStructure?.OtherPerks?.Perks;
      if (!OtherPerks || !OtherPerks.length) continue;
      for (const perk of OtherPerks) {
        const titleCasePerk = toTitleCase(perk);
        const formattedPerk = boardPayPerkMap?.[perk] || "";
        if (
          formattedPerk &&
          !allPerksData.some((item) => item.key === formattedPerk)
        ) {
          newDataRow[formattedPerk] = (
            <Tooltip title={`${perk}`}>
              <span style={{ fontSize: "15px" }}>✓</span>
            </Tooltip>
          );
          if (!perkOverview?.[formattedPerk]) {
            perkOverview[formattedPerk] = 1;
          } else {
            perkOverview[formattedPerk] += 1;
          }
        }
      }

      allPerksData.push(newDataRow);
    }
    const overviewRow = {
      Ticker: <b>Percent</b>,
      CompanyName: emdash,
    };
    Object.entries(perkOverview).forEach(([key, value]) => {
      overviewRow[key] = (
        <b>
          {(100 * (value / Object.keys(boardPayStructure).length)).toFixed(1) +
            "%"}
        </b>
      );
    });
    allPerksData.unshift(overviewRow);

    return allPerksData;
  }, [boardPayStructure]);

  return (
    <>
      {!isTier2 ? (
        <div
          className="comp-ai-section col-lg-9 executives "
          style={{ height: "100%", padding: 50 }}
        >
          <h3
            style={{
              textAlign: "center",
              fontWeight: "normal",
              fontSize: 22,
            }}
          >
            <p style={{ fontWeight: "bold" }}>
              This page is for C-Suite Comp&reg; Enterprise users only.
            </p>
            <br />
            <p>Contact your account manager to access Board Pay Structure.</p>
          </h3>
        </div>
      ) : (
        <>
          <div className="company-calculator-section col-lg-9 executives p-0">
            {!getBoardPayStructureColumns(combinedBoardStructure).length &&
            !boardPayStructure?.[companyInfo?.Ticker]
              ?.BoardCompensationStructure ? (
              <h2 style={{ textAlign: "center", marginTop: 50 }}>
                Board Pay Structure for {companyInfo?.Ticker} Coming Soon
              </h2>
            ) : !getBoardPayStructureColumns(combinedBoardStructure).length &&
              boardPayStructure?.[companyInfo?.Ticker]
                ?.BoardCompensationStructure ? (
              <div>
                <h2>Board Pay Structure</h2>
                <p style={{ fontSize: "1.5em", textAlign: "center" }}>
                  No Board Pay Structure for {companyInfo?.Company}
                </p>
              </div>
            ) : (
              <div>
                <h2>Board Pay Structure</h2>
                {/* {!PDFprocessing && (
                  <div style={{ width: "100%" }}>
                    <Button
                      style={{ marginBottom: 10 }}
                      onClick={() =>
                        exportToCSV(
                          formatDataForCSVexport(),
                          `${companyInfo?.Ticker} Board Pay Structure ${
                            new Date().toString().split("GMT-")[0]
                          }.csv`
                        )
                      }
                    >
                      Export Board Pay Structure as CSV
                    </Button>
                  </div>
                )} */}
                <Table
                  columns={getBoardPayStructureColumns(combinedBoardStructure)}
                  dataSource={getBoardPayStructureData(combinedBoardStructure)}
                  pagination={{ hideOnSinglePage: true }}
                  expandable={{
                    rowExpandable: (record) => record?.AdditionalDetails,
                    expandedRowRender: (record) =>
                      record?.AdditionalDetails ? (
                        <>
                          <p
                            style={{
                              margin: 0,
                              textAlign: "left",
                            }}
                          >
                            <b>Additional Details:</b>{" "}
                            {record?.AdditionalDetails}
                          </p>
                        </>
                      ) : (
                        false
                      ),
                  }}
                />
                {companyBoardPayStructure?.OtherPerks && (
                  <div>
                    <p style={{ marginTop: 10 }}>
                      <b>Perk(s): </b>

                      {companyBoardPayStructure.OtherPerks?.Perks?.join(", ")}
                    </p>
                    <p style={{ marginLeft: 15, marginTop: 5 }}>
                      {companyBoardPayStructure.OtherPerks?.AdditionalDetails}
                    </p>
                  </div>
                )}
                {companyBoardPayStructure?.AdditionalDetails && (
                  <p style={{ marginTop: 10 }}>
                    <b>Note: </b>
                    {companyBoardPayStructure?.AdditionalDetails}
                  </p>
                )}
                {companyBoardPayStructure?.Citation && (
                  <p style={{ marginTop: 10 }}>
                    <b>Citation: </b>

                    {companyBoardPayStructure?.Citation}
                  </p>
                )}
              </div>
            )}
          </div>
        </>
      )}
      <div style={{ margin: 10 }} />
      <hr />
      <h2 style={{ textAlign: "center" }}>
        Peer Group Board Pay Structure Comparison
      </h2>
      <p style={{ textAlign: "center" }}>
        {/* Board Pay Structure for {companyInfo.Company}'s Peer
            Group Coming Soon */}
        Select a Comparison Group to compare to {companyInfo.Company}
        's Board Pay Structure:
      </p>
      <CohortSelector
        heading={"Select Comparison Group:"}
        companyInfo={companyInfo}
        setCohortTableTickers={setCohortTableTickers}
        cohortTableSelector={cohortSelected}
        setCohortTableSelector={setCohortSelected}
        removeCustomCohort={true}
        additionalOptions={["None"]}
      />
      {cohortTableTickers.length ? (
        <div style={{ marginTop: 10 }}>
          <div className="title">
            <div
              className="cohort-selector"
              style={{
                display: "flex",
                marginTop: 10,
              }}
            >
              <h3
                style={{
                  fontSize: 20,
                  marginRight: 10,
                }}
              >
                {"Select Board Member Types:".split(" ").map((head, index) => (
                  <span key={index}>{head}&nbsp;</span>
                ))}
              </h3>
              <Checkbox.Group
                options={allBoardMemberTypes}
                value={boardMemberTypesSelected}
                onChange={onCheckboxChange}
              />
            </div>
          </div>
          <Table
            columns={getBoardPayStructureColumns(
              combinedPeerGroupBoardStructure,
              true
            )}
            dataSource={getBoardPayStructureData(
              combinedPeerGroupBoardStructure,
              true
            )}
            size={"small"}
            pagination={{ hideOnSinglePage: true, defaultPageSize: 25 }}
            expandable={{
              rowExpandable: (record) =>
                record?.AdditionalDetails ||
                boardPayStructure?.[record?.Ticker]?.BoardCompensationStructure
                  ?.AdditionalDetails ||
                boardPayStructure?.[record?.Ticker]?.BoardCompensationStructure
                  ?.Citation,
              expandedRowRender: (record) =>
                record?.AdditionalDetails ||
                boardPayStructure?.[record?.Ticker]?.BoardCompensationStructure
                  ?.AdditionalDetails ||
                boardPayStructure?.[record?.Ticker]?.BoardCompensationStructure
                  ?.Citation ? (
                  <>
                    {record?.AdditionalDetails && (
                      <p
                        style={{
                          margin: 0,
                          textAlign: "left",
                        }}
                      >
                        <b>* Additional Board Member Details:</b>{" "}
                        {record?.AdditionalDetails}
                      </p>
                    )}
                    {boardPayStructure?.[record?.Ticker]
                      ?.BoardCompensationStructure?.AdditionalDetails && (
                      <p
                        style={{
                          margin: 0,
                          textAlign: "left",
                        }}
                      >
                        <b>Company Details:</b>{" "}
                        {
                          boardPayStructure?.[record?.Ticker]
                            ?.BoardCompensationStructure?.AdditionalDetails
                        }
                      </p>
                    )}
                    {boardPayStructure?.[record?.Ticker]
                      ?.BoardCompensationStructure?.Citation && (
                      <p
                        style={{
                          margin: 0,
                          textAlign: "left",
                        }}
                      >
                        <b>Citation:</b>{" "}
                        {
                          boardPayStructure?.[record?.Ticker]
                            ?.BoardCompensationStructure?.Citation
                        }
                      </p>
                    )}
                  </>
                ) : (
                  false
                ),
            }}
          />
          <p>* Contains Additional Board Member Details</p>
          <div style={{ margin: 20 }} />
          <h3>Peer Group Board Pay Structure Overview</h3>
          <BoardStructureSummaryStatTable
            boardStructureData={getBoardPayStructureData(
              combinedPeerGroupBoardStructure,
              true
            ).filter((item) => item.CompanyName !== emdash)}
          />
          <h2>Perks Comparison Table</h2>
          <Table
            columns={getOtherPerksColumns.sort(
              (a, b) =>
                parseFloat(
                  getOtherPerksData
                    .find((item) => item.CompanyName === emdash)
                    [b.dataIndex].props.children.replace("%", "")
                ) -
                parseFloat(
                  getOtherPerksData
                    .find((item) => item.CompanyName === emdash)
                    [a.dataIndex].props.children.replace("%", "")
                )
            )}
            dataSource={getOtherPerksData}
            size={"small"}
            pagination={{ hideOnSinglePage: true, defaultPageSize: 25 }}
            scroll={{ x: "max-content" }} // Enable horizontal scrolling
            // expandable={{
            //   rowExpandable: (record) => record?.AdditionalDetails,
            //   expandedRowRender: (record) =>
            //     record?.AdditionalDetails ? (
            //       <>
            //         <p
            //           style={{
            //             margin: 0,
            //             textAlign: "left",
            //           }}
            //         >
            //           <b>Additional Details:</b> {record?.AdditionalDetails}
            //         </p>
            //       </>
            //     ) : (
            //       false
            //     ),
            // }}
          />
          <div
            style={{
              width: "100%",
              display: "flex",
              marginTop: 50,
              marginBottom: 10,
            }}
          >
            <h3 style={{ fontSize: 20, marginRight: 10, marginTop: 4 }}>
              {"View All Perks:".split(" ").map((head, index) => (
                <span key={index}>{head}&nbsp;</span>
              ))}
            </h3>
            <Select
              mode="multiple"
              allowClear
              style={{
                width: "100%",
              }}
              placeholder="View all Perks by Frequency"
              defaultValue={[]}
              // onSelect={(value) => updateCustomMeasuresSelected(value)}
              // onDeselect={(value) =>
              //   setCustomMeasuresSelected((prev) =>
              //     prev.filter((item) => item.value !== value)
              //   )
              // }
              // onClear={() => setCustomMeasuresSelected([])}
              value={[]}
              options={boardStructurePerksAndFrequency.map((item, index) => ({
                label: `${item.Perk} (${item.Count})`,
                value: item.Perk,
                key: item.Perk + index,
                count: item.Count,
              }))}
            />
          </div>
        </div>
      ) : null}
    </>
  );
}
