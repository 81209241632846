import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Button, Tooltip, Select } from "antd";
import { getExecutivePerks } from "../redux/actions/CompanyAction";
import { emdash } from "../utils/Constants/systemSetting";
import { exportToCSV } from "../utils/ExecutiveData/csvExport";
import CohortSelector from "./ExecutiveOverview/CohortSection/CohortSelector";
import { BoardStructureSummaryStatTable } from "./BoardPayStructure/BoardStructureSummaryStatTable";
import { ExecutivePerksSummaryStatTable } from "./ExecutivePerks/ExecutivePerksSummaryStatTable";

import executivePerksAndFrequency from "../assets/json/executivePerksAndFrequency.json";

export default function ExecutivePerks({ companyInfo, PDFprocessing }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.UserReducer);
  const { subscription } = useSelector((state) => state.CustomerReducer);
  const isTier2 = useMemo(() => {
    if (user?.tier2access || subscription?.plan?.metadata?.isTier2 === "true") {
      return true;
    }
    return false;
  }, [user, subscription]);

  const { executivePerks } = useSelector((state) => state.CompanyReducer);

  const [cohortTableTickers, setCohortTableTickers] = useState([]);
  const [cohortSelected, setCohortSelected] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("ACCESS_TOKEN");
    const ticker = companyInfo?.Ticker;
    if (ticker) {
      dispatch(getExecutivePerks([ticker, ...cohortTableTickers], token));
    }
  }, [companyInfo, cohortTableTickers, dispatch]);

  const getExecutivePerksCompanyColumns = (isPeerGroupTable = false) => {
    const defaultColumns = [
      {
        title: "Perk Name",
        dataIndex: "Name",
        key: "Name",
        align: "center",
      },
      {
        title: "Category",
        dataIndex: "Category",
        key: "Category",
        align: "center",
      },
      {
        title: "Max Payout",
        dataIndex: "MaxAmount",
        key: "MaxAmount",
        align: "center",
        render: (val, row) => {
          return row?.Description ? (
            <span>
              <Tooltip title={row?.Description}>
                <div style={{ cursor: "help" }}>
                  {val ? "$" + val.toLocaleString() : emdash + "*"}
                </div>
              </Tooltip>
            </span>
          ) : val ? (
            "$" + val.toLocaleString()
          ) : (
            emdash
          );
        },
      },
    ];
    if (isPeerGroupTable) {
      defaultColumns.unshift({
        title: "Company",
        dataIndex: "Company",
        key: "Company",
        align: "center",
      });
      defaultColumns.push({
        title: "Citation",
        dataIndex: "Citation",
        key: "Citation",
        align: "center",
      });
    }
    return defaultColumns;
  };
  const getExecutivePerksCompanyData = useCallback(
    (isPeerGroupTable = false) => {
      if (!executivePerks) return null;
      const companyOfInterestCompanyPerks =
        executivePerks?.[companyInfo.Ticker]?.ExecutivePerkDetails
          ?.CompanyPerks;
      if (!isPeerGroupTable) {
        return companyOfInterestCompanyPerks;
      }

      const peerGroupCompaniesPerks = [];
      for (const [ticker, companyPerkDetails] of Object.entries(
        executivePerks
      )) {
        if (ticker === companyInfo.Ticker) continue;
        const peerGroupCompanyPerks =
          companyPerkDetails?.ExecutivePerkDetails?.CompanyPerks;
        if (peerGroupCompanyPerks && peerGroupCompanyPerks.length)
          peerGroupCompaniesPerks.push(
            ...peerGroupCompanyPerks.map((item, index) => ({
              ...item,
              key: ticker + index,
              Ticker: ticker,
              Company: companyPerkDetails?.CompanyName,
            }))
          );
      }
      if (companyOfInterestCompanyPerks) {
        const companyOfInterestCompanyPerksWithCompanyDetails =
          companyOfInterestCompanyPerks.map((item, index) => ({
            ...item,
            key: companyInfo.Ticker + index,
            Ticker: companyInfo.Ticker,
            Company: executivePerks?.[companyInfo.Ticker]?.CompanyName,
          }));
        return [
          ...companyOfInterestCompanyPerksWithCompanyDetails,
          ...peerGroupCompaniesPerks,
        ];
      }
      return peerGroupCompaniesPerks;
    },
    [companyInfo, executivePerks]
  );

  const getExecutiveSpecificPerksColumns = useCallback(
    (isPeerGroupTable = false, isComparisonTable = false) => {
      const executiveSpecificPerks =
        executivePerks?.[companyInfo.Ticker]?.ExecutivePerkDetails
          ?.NEO_Specific_Benefits;

      const defaultColumns = [
        {
          title: "Executive Name",
          dataIndex: "name",
          key: "name",
          align: "center",
        },
        {
          title: "Position",
          dataIndex: "position",
          key: "position",
          align: "center",
        },
      ];

      if (isComparisonTable) {
        defaultColumns.push({
          title: "Ticker",
          dataIndex: "Ticker",
          key: "Ticker",
          align: "center",
        });
      }
      const otherExecutivePerks = [];
      const otherExecutivePerksCategories = [];

      executiveSpecificPerks.forEach((exec) => {
        if (!exec?.perks) return;
        exec.perks.forEach((perk) => {
          if (!perk?.Name) return;
          if (
            !otherExecutivePerks.includes(perk.Name) &&
            perk.Name !== "Total"
          ) {
            otherExecutivePerks.push(perk.Name);
            otherExecutivePerksCategories.push(perk?.Category || "");
          }
        });
      });

      const otherExecutivePerkColumns = otherExecutivePerks.map(
        (perkName, index) => ({
          title: perkName,
          dataIndex: perkName,
          key: perkName,
          category: otherExecutivePerksCategories[index],
          align: "center",
          onHeaderCell: (item) => {
            return item + "*";
          },
          render: (item) => (item ? item : emdash),
        })
      );

      const allExecutivePerkColumns = [
        ...defaultColumns,
        ...otherExecutivePerkColumns,
        {
          title: "Total",
          dataIndex: "Total",
          key: "Total",
          align: "center",
          render: (item) => (item ? item : emdash),
        },
      ];
      if (isPeerGroupTable) {
        allExecutivePerkColumns.unshift({
          title: "Company",
          dataIndex: "Company",
          key: "Company",
          align: "center",
        });
        allExecutivePerkColumns.push({
          title: "Citation",
          dataIndex: "Citation",
          key: "Citation",
          align: "center",
        });
      }
      return allExecutivePerkColumns;
    },
    [companyInfo, executivePerks]
  );

  const getExecutiveSpecificPerksData = useCallback(
    (isPeerGroupTable = false) => {
      if (!executivePerks || typeof executivePerks !== "object") return null;

      if (!isPeerGroupTable) {
        const executiveSpecificPerks =
          executivePerks?.[companyInfo.Ticker]?.ExecutivePerkDetails
            ?.NEO_Specific_Benefits;
        if (!executiveSpecificPerks) return null;
        const executiveSpecificPerksData = executiveSpecificPerks.map(
          (execData, index) => {
            if (!typeof execData === "Object") return {};
            const newExecData = {};
            newExecData["key"] = companyInfo.Ticker + index;
            newExecData["Ticker"] = companyInfo.Ticker;
            newExecData["Company"] =
              executivePerks?.[companyInfo.Ticker]?.CompanyName;
            for (const [key, val] of Object.entries(execData)) {
              if (key !== "perks") {
                newExecData[key] = val;
              } else {
                for (const perk of val) {
                  if (!perk.Name) continue;
                  newExecData[perk.Name] = perk?.ActualAmount ? (
                    perk?.Description ? (
                      <span>
                        <Tooltip title={perk?.Description}>
                          <div style={{ cursor: "help" }}>
                            {"$" + perk.ActualAmount.toLocaleString()}
                          </div>
                        </Tooltip>
                      </span>
                    ) : (
                      "$" + perk.ActualAmount.toLocaleString()
                    )
                  ) : (
                    emdash
                  );
                }
              }
            }
            return newExecData;
          }
        );
        return executiveSpecificPerksData;
      }
      if (isPeerGroupTable) {
        const allExecutiveSpecificPerksData = [];
        for (const [ticker, CompanyPerkDetails] of Object.entries(
          executivePerks
        )) {
          const executiveSpecificPerks =
            CompanyPerkDetails?.ExecutivePerkDetails?.NEO_Specific_Benefits;
          if (!executiveSpecificPerks) continue;
          const executiveSpecificPerksData = executiveSpecificPerks.map(
            (execData, index) => {
              if (!typeof execData === "Object") return {};
              const newExecData = {};
              newExecData["Ticker"] = ticker;
              newExecData["Company"] = executivePerks?.[ticker]?.CompanyName;
              newExecData["key"] = ticker + index;
              for (const [key, val] of Object.entries(execData)) {
                if (key !== "perks") {
                  newExecData[key] = val;
                } else {
                  const perkCategories = [];
                  for (const perk of val) {
                    if (!perk.Name || !perk?.Category) continue;
                    if (
                      !perkCategories.some(
                        (item) => item.Name === perk.Category
                      )
                    ) {
                      perkCategories.push({
                        Name: perk.Category,
                        Total: perk?.ActualAmount || 0,
                        Components: [
                          `${perk?.Name || ""}: ${perk?.Description || ""}`,
                        ],
                      });
                    } else {
                      const currentCategory = perkCategories.find(
                        (item) => item.Name === perk.Category
                      );
                      const newTotal = (currentCategory.Total +=
                        perk?.ActualAmount || 0);
                      const newComponents = currentCategory.Components;
                      newComponents.push(
                        `${perk?.Name || ""}: ${perk?.Description || ""}`
                      );
                      perkCategories.map((item) =>
                        item.Name === perk.Category
                          ? {
                              ...item,
                              Total: newTotal,
                              Components: newComponents,
                            }
                          : item
                      );
                    }
                  }

                  for (const category of perkCategories) {
                    newExecData[category.Name] = category?.Total ? (
                      <span>
                        <Tooltip
                          title={
                            !category?.Components ||
                            category?.Name === "Total" ? null : (
                              <span>
                                {category?.Components.map((item) => (
                                  <p style={{ marginBottom: 5 }}>
                                    <b>{item.split(": ")[0]}: </b>
                                    {item.split(": ")[1]}
                                  </p>
                                ))}
                              </span>
                            )
                          }
                        >
                          <div
                            style={{
                              cursor:
                                !category?.Components ||
                                category?.Name === "Total"
                                  ? null
                                  : "help",
                            }}
                          >
                            {"$" + category.Total.toLocaleString()}
                          </div>
                        </Tooltip>
                      </span>
                    ) : (
                      emdash
                    );
                  }
                }
              }
              return newExecData;
            }
          );
          allExecutiveSpecificPerksData.push(...executiveSpecificPerksData);
        }
        return allExecutiveSpecificPerksData;
      }
    },
    [companyInfo, executivePerks]
  );

  const executiveSpecificPeerGroupPerksColumns = useCallback(
    (isComparisonTable = false) => {
      const peerGroupPerksData = getExecutiveSpecificPerksData(true);
      if (!peerGroupPerksData || !peerGroupPerksData.length) return [];

      const excludeColumns = [
        "Ticker",
        "key",
        "name",
        "position",
        "Other",
        "Total",
        "Citation",
      ];

      const defaultColumns = [
        {
          title: "Company",
          dataIndex: "Company",
          key: "Company",
          align: "center",
        },
        {
          title: "Executive Name",
          dataIndex: "name",
          key: "name",
          align: "center",
        },
        {
          title: "Position",
          dataIndex: "position",
          key: "position",
          align: "center",
        },
      ];

      if (isComparisonTable) {
        defaultColumns.push({
          title: "Ticker",
          dataIndex: "Ticker",
          key: "Ticker",
          align: "center",
        });
      }

      for (const data of peerGroupPerksData) {
        for (const [key, val] of Object.entries(data)) {
          if (
            !excludeColumns.includes(key) &&
            !defaultColumns.some((item) => item.dataIndex === key)
          ) {
            defaultColumns.push({
              title: key,
              dataIndex: key,
              key: key,
              align: "center",
              render: (item) => (item ? item : emdash),
            });
          }
        }
      }

      defaultColumns.push({
        title: "Other",
        dataIndex: "Other",
        key: "Other",
        align: "center",
        render: (item) => (item ? item : emdash),
      });

      defaultColumns.push({
        title: "Total",
        dataIndex: "Total",
        key: "Total",
        align: "center",
        render: (item) => (item ? item : emdash),
      });
      return defaultColumns;
    },
    [getExecutiveSpecificPerksData]
  );

  const getRowExpandable = (record) =>
    record?.AdditionalDetails ||
    executivePerks?.[record?.Ticker]?.ExecutivePerkDetails?.AdditionalDetails ||
    executivePerks?.[record?.Ticker]?.ExecutivePerkDetails?.Citation;

  const getExpandedRowRender = (record) =>
    record?.AdditionalDetails ||
    executivePerks?.[record?.Ticker]?.ExecutivePerkDetails?.AdditionalDetails ||
    executivePerks?.[record?.Ticker]?.ExecutivePerkDetails?.CompanyPerks
      ?.Citation ? (
      <>
        {record?.AdditionalDetails && (
          <p
            style={{
              margin: 0,
              textAlign: "left",
            }}
          >
            <b>* Additional Board Member Details:</b>{" "}
            {record?.AdditionalDetails}
          </p>
        )}
        {executivePerks?.[record?.Ticker]?.ExecutivePerkDetails
          ?.AdditionalDetails && (
          <p
            style={{
              margin: 0,
              textAlign: "left",
            }}
          >
            <b>Additional Company Details:</b>{" "}
            {
              executivePerks?.[record?.Ticker]?.ExecutivePerkDetails
                ?.AdditionalDetails
            }
          </p>
        )}
        {executivePerks?.[record?.Ticker]?.ExecutivePerkDetails
          ?.CompanyPerks?.[0]?.Citation && (
          <p
            style={{
              margin: 0,
              textAlign: "left",
            }}
          >
            <b>Citation:</b>{" "}
            {
              executivePerks?.[record?.Ticker]?.ExecutivePerkDetails
                ?.CompanyPerks?.[0]?.Citation
            }
          </p>
        )}
      </>
    ) : (
      false
    );

  const getPerksComparisonData = useMemo(() => {
    if (!executivePerks) return [];
    const allPerksData = [];
    const perkOverview = {};
    const perksForCompanyAlreadyCounted = {};

    for (const [key, values] of Object.entries(executivePerks)) {
      const newDataRow = {
        Ticker: values?.Ticker,
        Company: values?.CompanyName,
      };
      const executivePerks =
        values?.ExecutivePerkDetails?.NEO_Specific_Benefits;
      if (!executivePerks || !executivePerks.length) continue;
      for (const executive of executivePerks) {
        const OtherPerks = executive?.perks;
        if (!OtherPerks || !OtherPerks.length) continue;
        for (const perk of OtherPerks) {
          const formattedPerk = perk?.Category || "";
          if (
            formattedPerk &&
            !allPerksData.some((item) => item.key === formattedPerk)
          ) {
            //TODO: Maybe show all possible perks (instead of .some, get .every, and show all names on hover text)
            newDataRow[formattedPerk] = (
              <Tooltip title={`${perk?.Name || ""}`}>
                <span style={{ fontSize: "15px" }}>✓</span>
              </Tooltip>
            );
            if (!perkOverview?.[formattedPerk]) {
              perkOverview[formattedPerk] = 1;
              perksForCompanyAlreadyCounted[formattedPerk] = [values?.Ticker];
            } else if (
              !perksForCompanyAlreadyCounted[formattedPerk].includes(
                values?.Ticker
              )
            ) {
              perkOverview[formattedPerk] += 1;
              perksForCompanyAlreadyCounted[formattedPerk].push(values?.Ticker);
            }
          }
        }
      }
      allPerksData.push(newDataRow);
    }

    const overviewRow = {
      Ticker: <b>Percent</b>,
      Company: emdash,
    };
    Object.entries(perkOverview).forEach(([key, value]) => {
      overviewRow[key] = (
        <b>
          {(100 * (value / Object.keys(executivePerks).length)).toFixed(1) +
            "%"}
        </b>
      );
    });
    allPerksData.unshift(overviewRow);

    return allPerksData;
  }, [executivePerks]);

  const formatDataForCSVexport = useCallback((awardsColumns, awardsData) => {
    const compensationDecisionOutputData = [];
    if (awardsData?.length) {
      compensationDecisionOutputData.push(
        ...awardsData?.map((item) => {
          return { ...item };
        })
      );
    }
    const compensationDecisionHeaders = [];
    awardsColumns.forEach((item) => {
      if (!compensationDecisionHeaders.includes(item.dataIndex)) {
        compensationDecisionHeaders.push(item.dataIndex);
      }
    });
    const formattedBoardPayData = compensationDecisionOutputData.map((item) => {
      const formattedItem = {};
      awardsColumns.forEach((keyDetails, index) => {
        if (keyDetails?.dataIndex) {
          const itemData =
            item?.[keyDetails.dataIndex]?.props?.children?.props?.children
              ?.props?.children || item?.[keyDetails.dataIndex];

          formattedItem[`"${keyDetails.title}"`] =
            itemData && (itemData.length > 2 || itemData >= 0)
              ? itemData.toString()?.replaceAll(",", "")
              : "";
        }
      });
      return formattedItem;
    });
    return formattedBoardPayData;
  }, []);

  return (
    <>
      {!isTier2 ? (
        <div
          className="comp-ai-section col-lg-9 executives "
          style={{ height: "100%", padding: 50 }}
        >
          <h3
            style={{
              textAlign: "center",
              fontWeight: "normal",
              fontSize: 22,
            }}
          >
            <p style={{ fontWeight: "bold" }}>
              This page is for C-Suite Comp&reg; Enterprise users only.
            </p>
            <br />
            <p>Contact your account manager to access Executive Perks.</p>
          </h3>
        </div>
      ) : (
        <>
          {!getExecutivePerksCompanyData() ? (
            <div className="company-calculator-section col-lg-9 executives p-0">
              <h2 style={{ textAlign: "center", marginTop: 50 }}>
                Executive Perquisites for {companyInfo?.Ticker} Coming Soon
              </h2>
            </div>
          ) : (
            <>
              <div className="company-calculator-section col-lg-9 executives p-0">
                <div>
                  <h2>Company-Wide Executive Perquisites</h2>
                  {!PDFprocessing && (
                    <div style={{ width: "100%" }}>
                      <Button
                        style={{ marginBottom: 10 }}
                        onClick={() =>
                          exportToCSV(
                            formatDataForCSVexport(
                              getExecutivePerksCompanyColumns(),
                              getExecutivePerksCompanyData()
                            ),
                            `${companyInfo?.Ticker} Company-Wide Perks ${
                              new Date().toString().split("GMT-")[0]
                            }.csv`
                          )
                        }
                      >
                        Export Company-Wide Perks as CSV
                      </Button>
                    </div>
                  )}
                  {getExecutivePerksCompanyData() &&
                  getExecutivePerksCompanyData()?.length ? (
                    <>
                      <p style={{ marginBottom: 10, marginLeft: 5 }}>
                        Hover over a perquisite's dollar amount for more
                        details.
                      </p>
                      <Table
                        columns={getExecutivePerksCompanyColumns()}
                        dataSource={getExecutivePerksCompanyData()}
                        pagination={{ hideOnSinglePage: true, pageSize: 25 }}
                      />
                    </>
                  ) : (
                    <h3
                      style={{
                        textAlign: "center",
                        fontWeight: "normal",
                        fontSize: 22,
                      }}
                    >
                      This Company does not have Company-Wide Perquisites
                    </h3>
                  )}

                  {executivePerks?.[companyInfo?.Ticker]?.ExecutivePerkDetails
                    ?.CompanyPerks &&
                    executivePerks?.[companyInfo?.Ticker]?.ExecutivePerkDetails
                      ?.CompanyPerks?.[0]?.Citation && (
                      <p style={{ marginTop: 10 }}>
                        <b>Citation: </b>
                        {
                          executivePerks?.[companyInfo?.Ticker]
                            ?.ExecutivePerkDetails?.CompanyPerks?.[0]?.Citation
                        }
                      </p>
                    )}
                </div>
              </div>
              <div className="company-calculator-section col-lg-12 executives mt-5 p-0">
                <div>
                  <h2>Executive-Specific Perquisites</h2>
                  {!PDFprocessing && (
                    <div style={{ width: "100%" }}>
                      <Button
                        style={{ marginBottom: 10 }}
                        onClick={() =>
                          exportToCSV(
                            formatDataForCSVexport(
                              getExecutiveSpecificPerksColumns(),
                              getExecutiveSpecificPerksData()
                            ),
                            `${companyInfo?.Ticker} Executive-Specific Perks ${
                              new Date().toString().split("GMT-")[0]
                            }.csv`
                          )
                        }
                      >
                        Export Executive-Specific Perks as CSV
                      </Button>
                    </div>
                  )}
                  {getExecutiveSpecificPerksData() &&
                  getExecutiveSpecificPerksData()?.length ? (
                    <>
                      <p style={{ marginBottom: 10, marginLeft: 5 }}>
                        Hover over a perquisite's dollar amount for more
                        details.
                      </p>

                      <Table
                        columns={getExecutiveSpecificPerksColumns()}
                        dataSource={getExecutiveSpecificPerksData()}
                        pagination={{ hideOnSinglePage: true, pageSize: 25 }}
                      />
                    </>
                  ) : (
                    <h3
                      style={{
                        textAlign: "center",
                        fontWeight: "normal",
                        fontSize: 22,
                      }}
                    >
                      This Company does not have Executive-Specific Perquisites
                    </h3>
                  )}

                  {executivePerks?.[companyInfo?.Ticker]?.ExecutivePerkDetails
                    ?.NEO_Specific_Benefits &&
                    executivePerks?.[companyInfo?.Ticker]?.ExecutivePerkDetails
                      ?.NEO_Specific_Benefits?.[0]?.Citation && (
                      <p style={{ marginTop: 15, marginLeft: 5 }}>
                        <b>Citation: </b>
                        {
                          executivePerks?.[companyInfo?.Ticker]
                            ?.ExecutivePerkDetails?.NEO_Specific_Benefits?.[0]
                            ?.Citation
                        }
                      </p>
                    )}
                  {executivePerks?.[companyInfo?.Ticker]?.ExecutivePerkDetails
                    ?.AdditionalDetails && (
                    <p style={{ marginTop: 25, marginLeft: 5 }}>
                      <b>Note: </b>

                      {
                        executivePerks?.[companyInfo?.Ticker]
                          ?.ExecutivePerkDetails?.AdditionalDetails
                      }
                    </p>
                  )}
                </div>
              </div>
              <div style={{ margin: 10 }} />
              <hr />
              <h2 style={{ textAlign: "center" }}>
                Executive Perks Peer Group Comparison
              </h2>
              <p style={{ textAlign: "center" }}>
                {/* Executive Perks Comparison for {companyInfo.Company}'s Peer
                Group Coming Soon */}
                Select a Comparison Group to compare to {companyInfo.Company}
                's Executive Perks:
              </p>
              <CohortSelector
                heading={"Select Comparison Group:"}
                companyInfo={companyInfo}
                setCohortTableTickers={setCohortTableTickers}
                cohortTableSelector={cohortSelected}
                setCohortTableSelector={setCohortSelected}
                removeCustomCohort={true}
                additionalOptions={["None"]}
              />
              {cohortSelected && cohortSelected !== "None" ? (
                <div style={{ marginTop: 20 }}>
                  <h2>Peer Group Company-Wide Executive Perquisites</h2>
                  {getExecutivePerksCompanyData(true) &&
                  getExecutivePerksCompanyData(true)?.length ? (
                    <>
                      <p style={{ marginBottom: 10, marginLeft: 5 }}>
                        Hover over a perquisite's dollar amount for more
                        details.
                      </p>
                      <Table
                        columns={getExecutivePerksCompanyColumns(true)}
                        dataSource={getExecutivePerksCompanyData(true)}
                        pagination={{ hideOnSinglePage: true, pageSize: 25 }}
                        expandable={{
                          rowExpandable: (record) => getRowExpandable(record),
                          expandedRowRender: (record) =>
                            getExpandedRowRender(record),
                        }}
                      />
                    </>
                  ) : (
                    <h3
                      style={{
                        textAlign: "center",
                        fontWeight: "normal",
                        fontSize: 22,
                      }}
                    >
                      This Company does not have Company-Wide Perquisites
                    </h3>
                  )}
                  <h2>Executive-Specific Perquisites</h2>
                  {getExecutiveSpecificPerksData() &&
                  getExecutiveSpecificPerksData()?.length ? (
                    <>
                      <p style={{ marginBottom: 10, marginLeft: 5 }}>
                        Hover over a perquisite's dollar amount for more
                        details.
                      </p>

                      <Table
                        columns={executiveSpecificPeerGroupPerksColumns()}
                        dataSource={getExecutiveSpecificPerksData(true)}
                        pagination={{ hideOnSinglePage: true, pageSize: 25 }}
                        size="small"
                        expandable={{
                          rowExpandable: (record) => getRowExpandable(record),
                          expandedRowRender: (record) =>
                            getExpandedRowRender(record),
                        }}
                      />
                    </>
                  ) : (
                    <h3
                      style={{
                        textAlign: "center",
                        fontWeight: "normal",
                        fontSize: 22,
                      }}
                    >
                      This Company does not have Executive-Specific Perquisites
                    </h3>
                  )}
                  <div style={{ margin: 20 }} />
                  <h3>Peer Group Executive Perks Overview</h3>
                  <ExecutivePerksSummaryStatTable
                    executivePerksData={getExecutiveSpecificPerksData(
                      true
                    ).filter((item) => item.Company !== emdash)}
                    executivePerksColumns={executiveSpecificPeerGroupPerksColumns()}
                  />
                  <h2>Executive Perks Comparison Table</h2>
                  <Table
                    columns={executiveSpecificPeerGroupPerksColumns(true)
                      .filter(
                        (item) =>
                          ![
                            "name",
                            "position",
                            "Company",
                            "Other",
                            "Total",
                          ].includes(item.dataIndex)
                      )
                      .sort(
                        (a, b) =>
                          parseFloat(
                            getPerksComparisonData
                              .find((item) => item.Company === emdash)
                              [b.dataIndex].props.children.replace("%", "")
                          ) -
                          parseFloat(
                            getPerksComparisonData
                              .find((item) => item.Company === emdash)
                              [a.dataIndex].props.children.replace("%", "")
                          )
                      )}
                    dataSource={getPerksComparisonData}
                    size={"small"}
                    pagination={{ hideOnSinglePage: true, defaultPageSize: 25 }}
                    // scroll={{ x: "max-content" }} // Enables horizontal scrolling
                  />
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      marginTop: 50,
                      marginBottom: 10,
                    }}
                  >
                    <h3 style={{ fontSize: 20, marginRight: 10, marginTop: 4 }}>
                      {"View All Perks:".split(" ").map((head, index) => (
                        <span key={index}>{head}&nbsp;</span>
                      ))}
                    </h3>
                    <Select
                      mode="multiple"
                      allowClear
                      style={{
                        width: "100%",
                      }}
                      placeholder="View all Perks by Frequency"
                      defaultValue={[]}
                      // onSelect={(value) => updateCustomMeasuresSelected(value)}
                      // onDeselect={(value) =>
                      //   setCustomMeasuresSelected((prev) =>
                      //     prev.filter((item) => item.value !== value)
                      //   )
                      // }
                      // onClear={() => setCustomMeasuresSelected([])}
                      value={[]}
                      options={executivePerksAndFrequency.map(
                        (item, index) => ({
                          label: `${item.Perk} (${item.Count})`,
                          value: item.Perk,
                          key: item.Perk + index,
                          count: item.Count,
                        })
                      )}
                    />
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}
