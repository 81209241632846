import React, { useEffect, useState } from "react";
import "../css/product.css";
import { Button } from "../JSS/Button";
import { useDispatch, useSelector } from "react-redux";
import { getAllPlans } from "../redux/actions/PlansAction";
import { Link } from "react-router-dom";

export default function Product() {
  const dispatch = useDispatch();

  const { plans } = useSelector((state) => state.PlansReducer);

  const sortedPlans = plans
    .filter((plan) => plan.active)
    .filter((plan) => plan.metadata?.displayOnFrontend === "true")
    .sort((a, b) => {
      return a.price - b.price;
    });

  const [active, setActive] = useState({
    month: "",
    year: "active",
  });

  const [plan, setPlan] = useState("");

  useEffect(() => {
    // console.log(plans);
    if (!plans.length) {
      dispatch(getAllPlans()); // load plans if they weren't loaded on homepage
      setPlan(plans[plans.length - 1]?.id);
    }
  }, [plans, dispatch]);

  const changeActive = (tab) => {
    const values = { ...active };
    for (let key in values) {
      values[key] = "";
    }
    values[tab] = "active";
    setActive({ ...values });
  };

  const renderPayPlan = () => {
    return sortedPlans.map((plan, index) => {
      return (
        <div
          key={index}
          className={`col-lg-${12 / Number(sortedPlans.length)} `}
        >
          <div
            onClick={() => {
              changeActive(plan.interval);
              setPlan(plan.id);
            }}
            className={`${plan.interval} product-item ${active[plan.interval]}`}
            style={{ margin: 10 }}
          >
            {plan.interval === "year" && (
              <div className="popular">Best Value</div>
            )}
            <div className="title">
              <h3>
                {(plan.interval === "month" && "Monthly Plan") ||
                  (plan.interval === "year" && "Annual Plan")}
              </h3>
              <p style={{ marginTop: "-5px" }}>
                Billed{" "}
                {(plan.interval === "month" && "monthly") ||
                  (plan.interval === "year" && "yearly")}
              </p>

              <br />

              <p>
                <span>
                  $
                  {(
                    (plan.interval === "month" ? plan.price : plan.price / 12) /
                    100
                  ).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </span>
                /mo ($
                {(
                  (plan.interval === "year" ? plan.price : plan.price * 12) /
                  100
                ).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
                /{"yr"})
              </p>
            </div>

            <br />
            <br />

            <img
              src={require("../assets/lock.svg").default}
              style={{ width: "20px", position: "absolute" }}
              alt="lock icon"
            />
            <div className="col">
              <div className="row-lg-6">
                <p style={{ paddingLeft: 30 }}>Secure Transaction</p>
              </div>

              <div className="row-lg-6">
                <div className="buttonContainer">
                  <Link to={`/checkout/${plan.id}`}>
                    <Button primary>Buy&nbsp;Now</Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <div className="product">
      <div className="container ">
        <h2 style={{ paddingBottom: 40 }}>
          {" "}
          Gain full access to C-suite Comp&reg; data and visualizations:
        </h2>
        <div className="row justify-content-center">{renderPayPlan()}</div>
        <div style={{ marginTop: 30, paddingBottom: 30 }}>
          <h3>
            <Link to="/contact">Contact us</Link> for enterprise subscriptions
            (more than 10 users).
          </h3>
        </div>
      </div>
    </div>
  );
}
