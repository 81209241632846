import React from "react";
import "../css/about.css";
import gonzales from "../assets/about/boardMembers/gonzales.jpeg";
import rogers from "../assets/about/boardMembers/rogers.jpg";
import ahmed from "../assets/about/boardMembers/ahmed.jpeg";
import baff from "../assets/about/boardMembers/baff.png";
// import laviter from "../assets/about/boardMembers/laviter.png";

export default function BoardOfAdvisors() {
  return (
    <div className="about board-members">
      <div className="container main">
        <div className="col-md hero">
          <h1>Board of Advisors</h1>
        </div>

        <div className="container section">
          <h2>Asif Ahmed</h2>
          <div className="image container">
            <img src={ahmed} alt="Asif Ahmed" />
          </div>
          <span>
            <p>
              For 22 years, affluent individuals and families, family offices
              and institutions have chosen Asif Ahmed to help them define and
              pursue financial freedom.  They are corporate executives, health
              care professionals, lawyers, engineers, business owners, real
              estate developers, athletes and retirees. Each client and Asif
              collaborate to co-design many different but related aspects of
              their financial lives, including portfolio construction and risk
              management; tax, cash and liquidity planning and management
              strategies, use of custom credit, valuations of their businesses,
              insurance planning.
            </p>
            <p>
              At Morgan Stanley, he is a senior partner of The Wrigley Group and
              a member of the team’s Investment Committee. Asif has lived in
              three different continents and is fluent in multiple languages.
              His distinctive international exposure has provided him with
              invaluable world context, wisdom and foresight around global
              economics, commerce and geopolitics.
            </p>
            <p>
              Asif studied psychology at The University of Houston, in Texas. He
              started his career in wealth management in 2000 at Bank of America
              Merrill Lynch, spending over ten years with the firm. In 2011 he
              transitioned to JP Morgan Wealth Management. He joined Morgan
              Stanley in February 2022. Asif currently resides in Arizona, USA
              with his family. He brings to bear a formidable culture of
              excellence and continuous improvement to
              C&#8209;Suite&nbsp;Comp&reg;.
            </p>
          </span>
        </div>
        <div className="container section">
          <h2>Ray Gonzales</h2>
          <div className="image container">
            <img src={gonzales} alt="Ray Gonzales" />
          </div>
          <span>
            <p>
              Ray Gonzales is a Senior Human Resources Executive who is
              currently Founder and Principal of an Independent HR Coaching and
              Consulting Firm. Ray previously served in Sr. Human Resources
              roles for several companies, primarily in the Energy Industry,
              including CHRO for six companies.{" "}
            </p>
            <p>
              Ray spent 20 years of his career with ConocoPhillips where he was
              in many different HR roles including several HR Director roles and
              VP of HR for Dubai Petroleum Company. Other Companies where Ray
              had the top HR role include Arizona Public Service, Continental
              Resources, Seahawk Drilling and Freeport LNG.{" "}
            </p>
            <p>
              In these roles, he was responsible for overseeing all areas of the
              Human Resources functions including Talent Acquisition, Talent
              Management, Compensation, Employee Benefits, Human Resources
              Systems and Learning and Development. In this capacity, Ray would
              work with the Board of Directors on Executive Compensation,
              Succession Planning, and overall HR Strategic Planning.{" "}
            </p>
            <p>
              Ray has an extensive background in Executive Compensation having
              previously worked with six different Compensation Committees and
              with most of the top Executive Consulting Firms.
            </p>
            <p>
              Ray received an undergraduate degree in Business Administration
              Human Resources from Eastern New Mexico University and an MBA from
              the University of Colorado Denver. Ray has also received a
              Coaching Certification from the Certified Coaches Federation and a
              Diversity, Equity and Inclusion (DEI) Certificate from the
              National Diversity Council.
            </p>
            <p>
              Ray brings to C&#8209;Suite&nbsp;Comp&reg; his extensive
              knowledge, network, and deep insight into the work flows of
              executive compensation professionals.
            </p>
          </span>
        </div>
        <div className="container section">
          <h2>Dr. Phil Rogers</h2>
          <div className="image container">
            <img src={rogers} alt="Dr. Phil Rogers" />
          </div>
          <span>
            <p>
              Philip D. Rogers received a B.S. in Mathematics from Rensselaer
              Polytechnic Institute and both an M.S. and Ph.D. in Operations
              Research from the University of California at Berkeley, with
              minors in Statistics and Mathematical Economics. Following a
              33-year career at Exxon Corporation, Dr. Rogers joined the
              University of Houston in 2003 where he taught courses in
              statistics, business modeling and decision making and was awarded
              a university-wide teaching excellence award in 2011. From January
              of 2014 to May of 2015, he taught business modeling and
              optimization at the W.P. Carey School of Business at Arizona State
              University. He now teaches at the Marshall School of Business at
              the University of Southern California.
            </p>
            <p>
              During his years with Exxon, Dr. Rogers developed many
              sophisticated mathematical models that were used to optimize a
              wide variety of the Corporation's business operations. Examples
              include competitive bidding strategies, scheduling of airport
              refueling operations, short-term borrowing strategies,
              optimization of worldwide product manufacturing and distribution,
              and the structure of catastrophic loss insurance policies.
            </p>
            <p>
              As Manager of Operations Research for Exxon's affiliate in
              Australia, Dr. Rogers built the country's preeminent Operations
              Research group. In addition to multi-year assignments in London
              and Brussels, he served as Coordinator of all of Exxon's worldwide
              Operations Research activities. In that capacity, he was
              responsible for the allocation of a large R&D budget, for the
              development of Exxon's expert systems program, and for spreading
              throughout the Corporation the use of analytical techniques for
              production scheduling and for handling problems with multiple
              criteria.
            </p>
            <p>
              Dr. Rogers also managed Treasury operations at Exxon's corporate
              headquarters and held several managerial positions in Information
              Systems in various Exxon locations.
            </p>
            <p>
              Dr. Rogers has had varied consulting assignments with numerous
              client companies and has developed proprietary trading and options
              models. His personal interests include skiing, squash, tennis and
              the movies.
            </p>
            <p>
              Dr. Rogers brings his extensive knowledge and experience in
              mathematical modeling, statistical analysis, survey analysis, and
              stock and option trading to advise C&#8209;Suite&nbsp;Comp&reg; on
              analytical frameworks to apply on executive compensation data.
            </p>
          </span>
        </div>

        <div className="container section">
          <h2>David Baff</h2>
          <div className="image container">
            <img src={baff} alt="David Baff" />
          </div>
          <span>
            <p>
              David Baff, a former senior Human Resources executive, worked at
              Comcast, a global media and technology company headquartered in
              Philadelphia, for over 27 years before retiring at the end of
              2023. As the Senior Vice President of Executive Compensation,
              David led the company’s enterprise-wide executive compensation
              function. David was a routine presenter to the Compensation
              Committee of the Board of Directors, and a periodic speaker at
              Merrill Lynch and Fidelity annual client conferences.
            </p>
            <p>
              Prior to joining Comcast, David was a Senior Manager in the Audit
              practice of Ernst & Young in Philadelphia, where he had audit
              responsibilities for various companies across a wide range of
              industries.
            </p>
            <p>
              David, a graduate of Drexel University, is a CPA (Certified Public
              Accountant) and CMA (Certified Management Accountant). He brings
              his extensive knowledge of executive compensation to
              C&#8209;Suite&nbsp;Comp&reg;.
            </p>
          </span>
        </div>
        {/* <div className="container section">
          <h2>John M. Laviter</h2>
          <div className="image container">
            <img src={laviter} alt="John M. Laviter" />
          </div>
          <span>
            <p>
              John M. Laviter founded Northpoint Asset Management, Inc. in 2006,
              and served as Chairman and Chief Executive Officer for 18 years,
              departing in 2023 at the successful disposition of the Company to
              LP First Capital. At the time of his departure, Northpoint ranked
              #2 in Utah and #75 in the U.S. for growth in Real Estate (INC
              5000), held operations in 44 cities in 23 U.S. states, $178
              million in sales, and managed $5 billion in real estate assets for
              retail and institutional clientele. Previously, he was an asset
              manager for Kunz Investments, Inc., a private-equity group in San
              Francisco.
            </p>
            <p>
              Mr. Laviter graduated from Harvard Business School and received an
              MBA from the International School of Management in 2010. He
              graduated from Brigham Young University with Academic Honors in
              2004. He served as a Board Trustee for Southern Virginia
              University from 2020 - 2023.
            </p>
            <p>
              As an expert in organizational and business development, and
              financial strategy, Mr. Laviter is critical in the evolution of
              C&#8209;Suite&nbsp;Comp&reg;'s long-term growth initiatives.
            </p>
          </span>
        </div> */}
      </div>
    </div>
  );
}
