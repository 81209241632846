import React, { useState, useCallback } from "react";
import { emdash } from "../../utils/Constants/systemSetting";
import {
  toDollar,
  customParseFloat,
} from "../../utils/Constants/systemSetting";

export function ExecutivePerksSummaryStatTable({
  executivePerksData,
  executivePerksColumns,
}) {
  const [customPercentile, setCustomPercentile] = useState(0);

  const renderSummaryStatsTable = useCallback(
    (data) => {
      const table = {};
      executivePerksColumns.forEach((item) => {
        if (!["name", "position", "Company"].includes(item.dataIndex)) {
          table[item.dataIndex] = [];
        }
      });

      for (let i = 0; i < data.length; i++) {
        for (let key in table) {
          let item = data[i]?.[key];
          if (item) {
            const actualValue =
              item?.props?.children?.props?.children?.props?.children;
            if (actualValue) {
              const intValue = parseInt(
                actualValue.replace("$", "").replace(",", "")
              );
              table[key].push(intValue);
            }
          }
        }
      }

      const quantile = (sorted, q) => {
        const pos = (sorted.length - 1) * q;
        const base = Math.floor(pos);
        const rest = pos - base;

        if (sorted[base + 1] !== undefined) {
          return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
        } else {
          return sorted[base];
        }
      };

      return Object.entries(table)
        .filter(([key, value]) => value.length)
        .map(([key, value], index) => {
          const sorted = value.sort((a, b) => a - b);
          const len = sorted.length;
          const total = value.reduce((total, arg) => total + parseInt(arg), 0);

          const min = toDollar(sorted[0]);
          const max = toDollar(sorted[len - 1]);
          const oneQuarter = toDollar(quantile(sorted, 0.25));
          const half = toDollar(quantile(sorted, 0.5));
          const threeQuarter = toDollar(quantile(sorted, 0.75));

          const customPercentileValue = toDollar(
            quantile(sorted, customPercentile / 100)
          );

          return (
            <tr key={index}>
              <th style={{ textAlign: "center" }}>{key}</th>
              <td>{min || emdash}</td>
              <td className="d-none d-lg-table-cell">{oneQuarter || emdash}</td>
              <td className="d-none d-lg-table-cell">{half || emdash}</td>
              <td className="d-none d-lg-table-cell">
                {threeQuarter || emdash}
              </td>
              <td>{max || emdash}</td>
              <td>{toDollar(Math.round(total / len)) || emdash}</td>
              <td>{len || emdash}</td>
              <td>{customPercentileValue || emdash}</td>
            </tr>
          );
        });
    },
    [customPercentile, executivePerksColumns]
  );

  return (
    <div className="c-table">
      <div className="summary-table">
        <div className="summary-stat-table">
          <table>
            <thead>
              <tr className="th-row">
                <th>Compensation Type</th>
                <th>Min</th>
                <th className="d-none d-lg-table-cell">25th</th>
                <th className="d-none d-lg-table-cell">50th</th>
                <th className="d-none d-lg-table-cell">75th</th>
                <th>Max</th>
                <th>Average</th>
                <th>n</th>
                <th style={{ width: 100 }}>
                  Custom Percentile
                  <input
                    className="custom"
                    type="number"
                    name="custom"
                    value={customPercentile}
                    onChange={(e) => {
                      let value = customParseFloat(e.target.value);
                      if (value < 0) {
                        value = 0;
                      } else if (value > 100) {
                        value = 100;
                      }
                      setCustomPercentile(value.toLocaleString());
                    }}
                  />
                </th>
              </tr>
            </thead>
            <tbody>{renderSummaryStatsTable(executivePerksData)}</tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
