import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  ZAxis,
  Tooltip as RechartsTooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import {
  DEFAULT_YEAR,
  emdash,
  MIN_YEAR,
} from "../../utils/Constants/systemSetting";
import { Button, Tooltip } from "antd";
import metricsToCompare from "./metricsToCompare.json";

export default function PeerGroupMetricsComparison({
  companyInfo,
  updatedTableTickers,
  PDFprocessing,
  getData,
  year,
  setYear,
}) {
  const { companies } = useSelector((state) => state.CompaniesReducer);

  const yearsAvailable = useMemo(() => {
    const yearsAvailable = [];
    for (let yr = new Date().getFullYear() - 1; yr >= MIN_YEAR; yr--) {
      yearsAvailable.push(yr);
    }
    return yearsAvailable;
  }, []);

  const renderTooltip = (props) => {
    const { active, payload } = props;

    if (active && payload && payload.length) {
      const data = payload[0] && payload[0].payload;

      return (
        <div
          style={{
            backgroundColor: "#fff",
            border: "1px solid #999",
            margin: 0,
            padding: 10,
            textAlign: "center",
          }}
        >
          <p>
            {data.multipleTickers.length ? (
              <b>Multiple Companies:</b>
            ) : (
              <span></span>
            )}
            <b>
              {data.multipleTickers.length ? (
                data.multipleTickers.map((t, i) => (
                  <p>
                    {i + 1}.{" "}
                    {companies?.[t]?.Company
                      ? `${companies?.[t]?.Company} `
                      : ""}
                    [{t}]
                  </p>
                ))
              ) : (
                <p>
                  {data?.companyName ? `${data.companyName} ` : ""}[
                  {data.ticker}]
                </p>
              )}
            </b>
          </p>
          <p>
            <span> {data.measureType}: </span>
            <b>
              {data.type === "dollar" ? "$" : ""}
              {data.measureValue.toLocaleString()}
              {data.type === "percent" ? "%" : ""}
              {data.measureType.includes("Pay Ratio") ? " : 1" : ""}
            </b>
          </p>
          <p>
            <span> Percentile within Peer Group: </span>
            <b>{data.percentile.toFixed(2)}</b>
          </p>
        </div>
      );
    }
    return null;
  };

  const [chartType, setChartType] = useState("measureValue");

  const MetricComparisonChart = ({ metric }) => {
    const data = getData(metric);
    const targetCompaniesData = getData(metric, [companyInfo.Ticker]);

    const parseDomain = (type) => {
      const minValue =
        type === "percentile"
          ? 0
          : Math.min(
              Math.min.apply(
                null,
                data.map((entry) => entry?.[type])
              )
            );
      const maxValue =
        type === "percentile"
          ? 100
          : Math.max(
              Math.max.apply(
                null,
                data.map((entry) => entry?.[type])
              )
            );
      return [minValue, maxValue];
    };

    const domain = parseDomain("size");
    const measureDomain = parseDomain(chartType);
    const range = [16, 225];

    const peerGroupCount = data.filter(
      (item) => !isNaN(parseFloat(item?.measureValue))
    ).length;

    const targetCompanyPercentile = parseFloat(
      Math.round(targetCompaniesData[0]?.percentile)
    );
    return (
      <div style={{ width: "100%" }}>
        {metric?.hoverText ? (
          <Tooltip title={metric.hoverText}>
            <h3 style={{ cursor: "help" }}>
              {(!metric?.isCurrent ? `${year} ` : "") + metric.title}
            </h3>{" "}
          </Tooltip>
        ) : (
          <h3>{(!metric?.isCurrent ? `${year} ` : "") + metric.title}</h3>
        )}
        <p style={{ textAlign: "left", marginLeft: 1, marginTop: -18 }}>
          (n = {peerGroupCount})
        </p>
        <div style={{ display: "flex" }}>
          <ResponsiveContainer width="100%" height={60}>
            <ScatterChart
              width={800}
              height={60}
              margin={{
                top: 10,
                right: !PDFprocessing ? 20 : 50,
                bottom: 0,
                left: 0,
              }}
              zIndex={10000}
            >
              <XAxis
                type="number"
                dataKey={chartType}
                name={chartType}
                // interval={0}
                domain={measureDomain}
                tickFormatter={(tick) => tick.toLocaleString()}
                tickLine={{ transform: "translate(0, -6)" }}
              />
              <YAxis
                type="number"
                dataKey="index"
                height={10}
                width={80}
                tick={false}
                tickLine={false}
                axisLine={false}
                // label={{ size: "Employee Count", position: "insideRight" }}
              />
              <ZAxis
                type="number"
                dataKey="size"
                domain={[0, 500]}
                range={range}
              />
              <RechartsTooltip
                cursor={{ strokeDasharray: "3 3" }}
                wrapperStyle={{ zIndex: 100 }}
                content={renderTooltip}
                active={false}
              />

              <Scatter
                data={data}
                fill={"var(--secondary)"}
                isAnimationActive={!PDFprocessing}
              />
              <Scatter
                data={targetCompaniesData}
                fill={"var(--red)"}
                isAnimationActive={!PDFprocessing}
              />
            </ScatterChart>
          </ResponsiveContainer>
          <div
            style={{
              fontSize: 18,
              fontWeight: "bold",
              width: 40,
              textAlign: "center",
            }}
          >
            {!isNaN(targetCompanyPercentile) && peerGroupCount > 1 ? (
              <p>
                {targetCompanyPercentile}
                {targetCompanyPercentile.toString().endsWith(1) ? (
                  <sup>st</sup>
                ) : targetCompanyPercentile.toString().endsWith(2) ? (
                  <sup>nd</sup>
                ) : targetCompanyPercentile.toString().endsWith(3) ? (
                  <sup>rd</sup>
                ) : (
                  <sup>th</sup>
                )}
              </p>
            ) : (
              <p>{emdash}</p>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className="table-filter borderBox"
      style={{ outline: "1px solid black", margin: 10, padding: 10 }}
    >
      <div
        style={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}
      >
        {updatedTableTickers.length > 500 ||
        updatedTableTickers.length === 0 ? (
          <div style={{ width: "100%", textAlign: "center", paddingTop: 10 }}>
            <h4 style={{ fontWeight: "bold" }}>
              {updatedTableTickers.length === 0
                ? "No Peer Group companies to compare."
                : "Too many companies in Peer Group."}
            </h4>
            <h4>
              Try adjusting filters or select a different Peer Group to view
              comparisons.
            </h4>
          </div>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              <div style={{ width: "100%" }}>
                <h4 style={{ fontWeight: "bold", textAlign: "center" }}>
                  Peer Group Metrics Comparisons
                </h4>
              </div>
              {!PDFprocessing && (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    marginTop: 15,
                  }}
                >
                  <h4
                    style={{
                      paddingRight: 10,
                      marginBottom: 10,
                      fontWeight: "bold",
                    }}
                  >
                    Switch to{" "}
                    {chartType === "measureValue" ? "Percentile" : "Value"}{" "}
                    View:{" "}
                  </h4>
                  <Button
                    onClick={() => {
                      setChartType((prev) =>
                        prev === "measureValue" ? "percentile" : "measureValue"
                      );
                    }}
                  >
                    Switch View
                  </Button>
                </div>
              )}
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center",
                marginTop: 15,
              }}
            >
              <Tooltip
                title={`Year for all Peer Metric Comparisons
            (e.g., if ${DEFAULT_YEAR} is selected, revenue will be filtered based on the ${DEFAULT_YEAR} value).`}
              >
                <h4
                  style={{
                    paddingRight: 10,
                    marginBottom: 10,
                    fontWeight: "bold",
                  }}
                >
                  Metric Comparison Year:{" "}
                </h4>
              </Tooltip>
              {!PDFprocessing ? (
                <select
                  name="year"
                  onChange={(e) => setYear(e.target.value)}
                  value={year}
                  style={{
                    width: 75,
                    textAlign: "center",
                    marginBottom: 2,
                    padding: 2,
                  }}
                >
                  {yearsAvailable.map((yr, index) => (
                    <option value={parseInt(yr)} key={index}>
                      {yr}
                    </option>
                  ))}
                </select>
              ) : (
                <div
                  style={{
                    width: 25,
                    textAlign: "center",
                    marginBottom: 2,
                    padding: 2,
                    fontSize: 16,
                  }}
                >
                  {year}
                </div>
              )}
            </div>
            {metricsToCompare
              .filter((metric) => !metric?.hideOnComparisonPage)
              .map((metric, index) => (
                <MetricComparisonChart metric={metric} key={index} />
              ))}
          </>
        )}
      </div>
    </div>
  );
}
