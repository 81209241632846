import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { useSelector } from "react-redux";
import { Table, Checkbox, Tooltip, Button, Input } from "antd";
import { Link } from "react-router-dom";
import CohortSelector from "./ExecutiveOverview/CohortSection/CohortSelector.js";
import { initFilter } from "../utils/DataFilter/initFilter.js";
import {
  emdash,
  hasHoverText,
  defaultPeerGroupOptions,
  DEFAULT_YEAR,
  getPercentileFromValue,
} from "../utils/Constants/systemSetting.js";
import PeerGroupsByMetricsTable from "./PeerAnalysis/peerGroupsByMetricsTable.js";
import PeerGroupMetricsComparison from "./PeerAnalysis/PeerGroupMetricsComparison.js";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { updateUser } from "../redux/actions/UserAction";
import UserService from "../services/UserService";
import { exportToCSV } from "../utils/ExecutiveData/csvExport.js";

import { measureOptions } from "../utils/ExecutiveData/executiveMeasure";
import metricsToCompare from "./PeerAnalysis/metricsToCompare.json";

import { getWeeklyReturns } from "../redux/actions/CompanyAction";

const peerGroupTypeMap = {
  "Analyst Peer Group": "AnalystCohort",
  "Proxy Peer Group": "ProxyCohort",
  "Proxy Peer Group (Performance)": "ProxyCohortPerformance",
  "Proxy Peer Group (Compensation)": "ProxyCohortCompensation",
  "Comp AI Proxy Peer Group": "CompAIProxyCohort",
  "Comp AI Proxy Peer Group (Performance)": "CompAIProxyCohortPerformance",
  "Comp AI Proxy Peer Group (Compensation)": "CompAIProxyCohortCompensation",
  "Create Peer Group With Metrics": "MetricsCohort",
};

export default function PeerGroupAnalysis({
  companyInfo,
  companyInfoComponent,
  PDFprocessing,
  printPDFasMultiplePages,
  setPrintPDFasMultiplePages,
}) {
  const dispatch = useDispatch();
  UserService.getUserInfo(localStorage.getItem("ACCESS_TOKEN"));

  // store peer group filters for navigating between subpages/ when conditional rendering
  const [filter, setFilter] = useState(JSON.parse(JSON.stringify(initFilter)));
  const [year, setYear] = useState(DEFAULT_YEAR);

  const user = useSelector((state) => state.UserReducer);
  const { subscription } = useSelector((state) => state.CustomerReducer);
  const isTier2 = useMemo(() => {
    if (user?.tier2access || subscription?.plan?.metadata?.isTier2 === "true") {
      return true;
    }
    return false;
  }, [user, subscription]);

  const peerGroupAccess = useMemo(() => {
    if (subscription?.plan?.metadata?.peerGroupAccess === "true") {
      return true;
    }
    return false;
  }, [subscription]);

  const { companies } = useSelector((state) => state.CompaniesReducer);

  const [cohortTableSelector, setCohortTableSelector] = useState("");
  const [peerGroupKey, setPeerGroupKey] = useState("");

  const [viewPeerGroupMetricsComparison, setViewPeerGroupMetricsComparison] =
    useState(false);

  const [customUserPeerGroups, setCustomUserPeerGroups] = useState(
    user?.customPeerGroups || {}
  );

  useMemo(() => {
    if (user?.customPeerGroups && Object.keys(user?.customPeerGroups).length) {
      setCustomUserPeerGroups(user?.customPeerGroups);
    }
  }, [user?.customPeerGroups]);

  // For creating custom peer group:
  const [overlappingCohortTableSelector, setOverlappingCohortTableSelector] =
    useState("");
  const [overlappingPeerGroupKey, setOverlappingPeerGroupKey] = useState("");

  const [cohortTableTickers, setCohortTableTickers] = useState([]);
  const [customCohortTickers, setCustomCohortTickers] = useState([]);

  const [customPeerGroupName, setCustomPeerGroupName] = useState(
    "Custom Peer Group 1"
  );

  const handleCustomPeerGroupNameChange = (e) => {
    let value = e.target.value;
    value = value.replace(/[^a-z0-9 ]/gi, "").slice(0, 25);
    setCustomPeerGroupName(value);
  };

  const isCustomPeerGroup = useCallback(
    (peerGroupKey) =>
      Object.keys(customUserPeerGroups).length &&
      Object.keys(customUserPeerGroups).some((key) => key === peerGroupKey),
    [customUserPeerGroups]
  );

  const alternatePeerGroupOptions = [
    { label: "Current Peer Group", value: "currentPeerGroup" },
    { label: "Mutual Peer Group", value: "mutualPeerGroup" },
    { label: "Reverse Peer Group", value: "reversePeerGroup" },
    { label: "Distant Peer Group", value: "distantPeerGroup" },
  ];
  const [alternatePeerGroupsSelected, setAlternatePeerGroupsSelected] =
    useState(["currentPeerGroup"]);

  const onAlternatePeerGroupChange = (peerGroupsSelected) => {
    setAlternatePeerGroupsSelected(peerGroupsSelected);
  };

  useEffect(() => {
    if (customUserPeerGroups && Object.keys(customUserPeerGroups).length) {
      for (const [peerGroupKey, peerGroupValues] of Object.entries(
        customUserPeerGroups
      )) {
        peerGroupTypeMap[peerGroupKey] = peerGroupKey;
      }
    }
    setPeerGroupKey(peerGroupTypeMap[cohortTableSelector]);
    // if (peerGroupKey === "MetricsCohort" || isCustomPeerGroup(peerGroupKey)) {
    setOverlappingPeerGroupKey(
      peerGroupTypeMap[overlappingCohortTableSelector]
    );
    // }
    if (isCustomPeerGroup(peerGroupKey)) {
      setCohortTableTickers(customUserPeerGroups[peerGroupKey]);
    }
  }, [
    cohortTableSelector,
    overlappingCohortTableSelector,
    peerGroupKey,
    overlappingPeerGroupKey,
    customUserPeerGroups,
    isCustomPeerGroup,
  ]);

  const alteredPeerGroupKey = overlappingPeerGroupKey
    ? overlappingPeerGroupKey
    : isCustomPeerGroup(peerGroupKey)
    ? "ProxyCohort"
    : peerGroupKey;

  const alteredPeerGroupName = useMemo(() => {
    for (const [key, val] of Object.entries(peerGroupTypeMap)) {
      if (val === alteredPeerGroupKey) {
        return key;
      }
    }
    return alteredPeerGroupKey || "";
  }, [alteredPeerGroupKey]);

  const getClosestPeerGroup = useCallback(
    (companyData, peerGroupKeyNeeded) => {
      if (!companyData || !peerGroupKeyNeeded) return [];
      if (companyData?.[peerGroupKeyNeeded]) {
        return companyData[peerGroupKeyNeeded];
      }
      if (isCustomPeerGroup(peerGroupKeyNeeded)) {
        return customUserPeerGroups[peerGroupKeyNeeded];
      }
      if (
        peerGroupKeyNeeded.includes("ProxyCohort") &&
        companyData?.["ProxyCohort"]
      ) {
        return companyData["ProxyCohort"];
      }
      return [];
    },
    [customUserPeerGroups, isCustomPeerGroup]
  );

  const alternatePeerGroups = useMemo(() => {
    // For comparisons, Proxy Peer Group Performance/Compensation will compare to other Proxy Peers
    // Since Performance/Compensation is a rare case
    const companyPeerGroup =
      peerGroupKey === "MetricsCohort" || isCustomPeerGroup(peerGroupKey)
        ? getClosestPeerGroup(companyInfo, alteredPeerGroupKey)
        : getClosestPeerGroup(companyInfo, peerGroupKey);

    //MutualPeerGroup means this company is on their peer group list and the other company has them as a peer too
    const mutualPeerGroup = companyPeerGroup
      ? companyPeerGroup.filter((ticker) =>
          getClosestPeerGroup(companies[ticker], alteredPeerGroupKey).includes(
            companyInfo.Ticker
          )
        )
      : [];

    // ReversePeerGroup means company has this company on their peer group, but this company does not
    const reversePeerGroup = companyPeerGroup
      ? [
          ...new Set(
            Object.values(companies)
              .filter(
                (company) =>
                  !companyPeerGroup.includes(company.Ticker) &&
                  getClosestPeerGroup(company, alteredPeerGroupKey).includes(
                    companyInfo.Ticker
                  )
              )
              .map((company) => company.Ticker)
          ),
        ]
      : [];

    // distantPeerGroup shows companies who your peers benchmark to, but you do not:
    const distantPeerGroup = companyPeerGroup
      ? [
          ...new Set(
            companyPeerGroup
              .filter((ticker) =>
                getClosestPeerGroup(companies[ticker], [alteredPeerGroupKey])
              )
              .map((ticker) =>
                getClosestPeerGroup(
                  companies[ticker],
                  alteredPeerGroupKey
                ).filter(
                  (ticker) =>
                    !companyPeerGroup || !companyPeerGroup.includes(ticker)
                )
              )
              .flat(1)
          ),
        ]
      : [];

    return {
      currentPeerGroup: companyPeerGroup,
      mutualPeerGroup,
      reversePeerGroup,
      distantPeerGroup,
    };
  }, [
    companyInfo,
    companies,
    peerGroupKey,
    alteredPeerGroupKey,
    getClosestPeerGroup,
    isCustomPeerGroup,
  ]);

  const [customPeerGroupTickersSelected, setCustomPeerGroup] = useState([]);

  const currentCompanyPeerGroup = useMemo(() => {
    return companyInfo?.alteredPeerGroupKey || [];
  }, [companyInfo]);

  useMemo(() => {
    setCustomPeerGroup(currentCompanyPeerGroup || []);
  }, [currentCompanyPeerGroup]);

  const handleCustomPeerGroupChange = useCallback(
    (ticker) => {
      // NOTE: could do a CIK lookup here with companies[ticker].CIK, then add the CIK code to the peer group as an array
      // Not sure which is better, so this will just track tickers for now:
      if (!customPeerGroupTickersSelected.includes(ticker)) {
        setCustomPeerGroup((prev) => [...prev, ticker]);
      } else {
        const newPeerGroup = [...customPeerGroupTickersSelected];
        newPeerGroup.splice(newPeerGroup.indexOf(ticker), 1);
        setCustomPeerGroup(newPeerGroup);
      }
    },
    [customPeerGroupTickersSelected]
  );

  const updatedTableTickers = useMemo(() => {
    let updatedTableTickers = [];
    if (alternatePeerGroupsSelected.includes("currentPeerGroup")) {
      updatedTableTickers = updatedTableTickers.concat(
        cohortTableTickers?.length
          ? cohortTableTickers
          : alternatePeerGroups?.currentPeerGroup
      );
    }
    if (alternatePeerGroupsSelected.includes("mutualPeerGroup")) {
      updatedTableTickers = updatedTableTickers.concat(
        alternatePeerGroups?.mutualPeerGroup
      );
    }
    if (alternatePeerGroupsSelected.includes("reversePeerGroup")) {
      updatedTableTickers = updatedTableTickers.concat(
        alternatePeerGroups?.reversePeerGroup
      );
    }
    if (alternatePeerGroupsSelected.includes("distantPeerGroup")) {
      updatedTableTickers = updatedTableTickers.concat(
        alternatePeerGroups?.distantPeerGroup
      );
    }
    if (customCohortTickers.length) {
      customCohortTickers.forEach((ticker) => {
        if (!updatedTableTickers.includes(ticker)) {
          updatedTableTickers.push(ticker);
        }
      });
    }

    return [...new Set(updatedTableTickers)];
  }, [
    cohortTableTickers,
    alternatePeerGroups,
    alternatePeerGroupsSelected,
    customCohortTickers,
  ]);

  const peerGroupTableColumns = useMemo(() => {
    const getPeerGroupName = (companyInfo, cohortTableSelector) => {
      // console.log(cohortTableSelector);
      if (isCustomPeerGroup(cohortTableSelector)) {
        return `the Custom Peer Group selected (${cohortTableSelector})`;
      } else if (
        cohortTableSelector &&
        cohortTableSelector.includes("Metric")
      ) {
        return "the Peer Group (based on Metrics Selected)";
      } else {
        return `${
          companyInfo?.Company || companyInfo?.Ticker
        }'s ${cohortTableSelector}`;
      }
    };

    const defaultPeerGroupTableColumns = [
      {
        title: `Ticker`,
        dataIndex: "peerGroupTicker",
        key: "peerGroupTicker",
        align: "center",
        render: (text, record) => {
          return (
            <span style={{ fontSize: 12 }}>
              {companies?.[record?.peerGroupTicker] ? (
                <Link to={`/company/${record?.peerGroupTicker}#peers`}>
                  {text}
                </Link>
              ) : (
                <span>{text}</span>
              )}
            </span>
          );
        },
        sorter: (a, b) => {
          return a.peerGroupTicker.localeCompare(b.peerGroupTicker);
        },
        defaultSortOrder: "ascend",
      },
      {
        title: `Company`,
        dataIndex: "peerGroupCompany",
        key: "peerGroupCompany",
        align: "center",
        render: (text, record) => {
          return (
            <span style={{ fontSize: 12 }}>
              {companies?.[record?.peerGroupTicker] ? (
                <Link to={`/company/${record?.peerGroupTicker}#peers`}>
                  {text}
                </Link>
              ) : (
                <span>{emdash}</span>
              )}
            </span>
          );
        },
        sorter: (a, b) => {
          return a?.peerGroupCompany?.localeCompare(b?.peerGroupCompany);
        },
      },
    ];
    const alternatePeerGroupColumns = [
      {
        title: (
          <Tooltip
            title={`These are the companies included in ${getPeerGroupName(
              companyInfo,
              cohortTableSelector
            )}.`}
            overlayClassName="white-link"
          >
            {hasHoverText(<span>Included in Peer Group</span>)}
          </Tooltip>
        ),
        dataIndex: "isInPeerGroup",
        key: "isInPeerGroup",
        align: "center",
        width: "150px",
      },
      {
        title: (
          <Tooltip
            title={`These companies are included in ${getPeerGroupName(
              companyInfo,
              cohortTableSelector
            )} and have ${
              companyInfo?.Company || companyInfo?.Ticker
            } listed in their  ${alteredPeerGroupName || cohortTableSelector}.`}
            overlayClassName="white-link"
          >
            {hasHoverText(<span>Mutual Peers</span>)}
          </Tooltip>
        ),
        dataIndex: "isMutualPeer",
        key: "isMutualPeer",
        align: "center",
        width: "100px",
      },
      {
        title: (
          <Tooltip
            title={`These companies have ${getPeerGroupName(
              companyInfo,
              cohortTableSelector
            )} listed in their  ${
              alteredPeerGroupName || cohortTableSelector
            }, but they are not included in ${
              companyInfo?.Company || companyInfo?.Ticker
            }'s ${alteredPeerGroupName || cohortTableSelector}.`}
            overlayClassName="white-link"
          >
            {hasHoverText(<span>Reverse Peer</span>)}
          </Tooltip>
        ),
        dataIndex: "isReversePeer",
        key: "isReversePeer",
        align: "center",
        width: "100px",
      },
      {
        title: (
          <Tooltip
            title={`One or more of ${
              companyInfo?.Company || companyInfo?.Ticker
            }'s peers have these companies listed in their ${
              alteredPeerGroupName || cohortTableSelector
            }, but they are not included in ${
              companyInfo?.Company || companyInfo?.Ticker
            }'s ${alteredPeerGroupName || cohortTableSelector}.`}
            overlayClassName="white-link"
          >
            {hasHoverText(<span>Distant Peer</span>)}
          </Tooltip>
        ),
        dataIndex: "isDistantPeer",
        key: "isDistantPeer",
        align: "center",
        width: "100px",
      },
    ];

    if (!PDFprocessing) {
      defaultPeerGroupTableColumns.unshift({
        title: (
          <>
            <p>Add to New Peer Group?</p>
            {updatedTableTickers.length > 0 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 1,
                }}
              >
                <p style={{ marginRight: 3 }}>Add All:</p>
                <Checkbox
                  checked={
                    customPeerGroupTickersSelected.length ===
                    updatedTableTickers.length
                  }
                  onChange={() =>
                    customPeerGroupTickersSelected.length ===
                    updatedTableTickers.length
                      ? setCustomPeerGroup([])
                      : setCustomPeerGroup(updatedTableTickers)
                  }
                />
              </div>
            )}
          </>
        ),
        dataIndex: "peerGroupAdditionsCheckbox",
        key: "peerGroupAdditionsCheckbox",
        align: "center",
        render: (text, record) => {
          return (
            <Checkbox
              style={{ fontSize: 12 }}
              checked={customPeerGroupTickersSelected.includes(
                record?.peerGroupTicker
              )}
              onChange={() =>
                handleCustomPeerGroupChange(record?.peerGroupTicker)
              }
            ></Checkbox>
          );
        },
        width: 100,
      });
    }

    return overlappingCohortTableSelector !== "None"
      ? // ||
        // (!isCustomPeerGroup(peerGroupKey) && peerGroupKey !== "MetricsCohort")
        defaultPeerGroupTableColumns.concat(alternatePeerGroupColumns)
      : defaultPeerGroupTableColumns;
  }, [
    alteredPeerGroupName,
    cohortTableSelector,
    companies,
    companyInfo,
    customPeerGroupTickersSelected,
    updatedTableTickers,
    overlappingCohortTableSelector,
    // peerGroupKey,
    PDFprocessing,
    handleCustomPeerGroupChange,
    isCustomPeerGroup,
  ]);

  function containsNumbers(str) {
    var regex = /\d/g;
    return regex.test(str);
  }

  useEffect(() => {
    if (isCustomPeerGroup(peerGroupKey) || peerGroupKey === "MetricsCohort") {
      setOverlappingCohortTableSelector("None");
    }
  }, [peerGroupKey, isCustomPeerGroup]);

  useEffect(() => {
    if (overlappingCohortTableSelector === "None") {
      if (peerGroupKey !== "MetricsCohort") {
        setAlternatePeerGroupsSelected(["currentPeerGroup"]);
      } else {
        setAlternatePeerGroupsSelected([]);
      }
    }
  }, [overlappingCohortTableSelector, peerGroupKey]);

  const peerGroupTableData = updatedTableTickers
    // .filter((ticker) => companies[ticker]?.Company) // To only show companies we have data for
    .filter((ticker) => !containsNumbers(ticker))
    .map((ticker, index) => {
      return {
        peerGroupTicker: ticker,
        peerGroupCompany: companies[ticker]?.Company,
        isInPeerGroup: (peerGroupKey === "MetricsCohort"
          ? getClosestPeerGroup(companyInfo, alteredPeerGroupKey)
          : getClosestPeerGroup(companyInfo, peerGroupKey)
        )?.includes(ticker) ? (
          <span>&#9826;</span>
        ) : (
          <span></span>
        ),
        isMutualPeer: alternatePeerGroups?.mutualPeerGroup?.includes(ticker) ? (
          <span>&#9830;</span>
        ) : (
          <span></span>
        ),
        isReversePeer: alternatePeerGroups?.reversePeerGroup?.includes(
          ticker
        ) ? (
          <span>&#9664;</span>
        ) : (
          <span></span>
        ),
        isDistantPeer: alternatePeerGroups?.distantPeerGroup?.includes(
          ticker
        ) ? (
          <span>&#9654;</span>
        ) : (
          <span></span>
        ),
        key: index,
      };
    });

  // //reset to defualt on page change
  // useEffect(() => {
  //   setAlternatePeerGroupsSelected(["currentPeerGroup"]);
  // }, [companyInfo]);

  useEffect(() => {
    if (peerGroupKey !== "MetricsCohort") {
      setOverlappingCohortTableSelector("None");
      setAlternatePeerGroupsSelected(["currentPeerGroup"]);
      setCustomCohortTickers([]);
    } else {
      setAlternatePeerGroupsSelected([]);
    }
  }, [peerGroupKey]);

  const [disabled, setDisabled] = useState(false);
  const [disabledReason, setDisabledReason] = useState("");

  const [cohortUpdateCount, setCohortUpdateCount] = useState(1);

  const handleSaveCohortSubmit = useCallback(
    async (e, peerGroupUpdateKey = "") => {
      try {
        e.preventDefault();

        const newUserDetails = {
          ...user,
        };
        if (newUserDetails?.customPeerGroups) {
          const currentCustomPeerGroups = user.customPeerGroups;
          currentCustomPeerGroups[peerGroupUpdateKey || customPeerGroupName] =
            customPeerGroupTickersSelected;
          newUserDetails.customPeerGroups = currentCustomPeerGroups;
        } else {
          const newPeerGroup = {};
          newPeerGroup[customPeerGroupName] = customPeerGroupTickersSelected;
          newUserDetails.customPeerGroups = newPeerGroup;
        }

        setDisabled(true);
        setDisabledReason("Currently Updating Peer Group List...");
        const { status, data } = await UserService.updateUser(newUserDetails);
        if (status == 200) {
          await dispatch(updateUser(newUserDetails));
          toast.success(
            `Custom Peer Group ${peerGroupUpdateKey ? "Updated" : "Added"}!` +
              (peerGroupUpdateKey ? "" : " Scroll up to see new peer group."),
            { duration: 3000 }
          );
          await UserService.getUserInfo(localStorage.getItem("ACCESS_TOKEN"));
          setCohortUpdateCount((prev) => prev + 1);
          setCohortTableSelector(peerGroupUpdateKey || customPeerGroupName);
          // setDisabled(false);
          setDisabledReason("");
        } else {
          setDisabled(false);
          setDisabledReason("");
        }
      } catch (err) {
        //   console.log(err);
        console.log(err?.response);
        toast.error(
          err?.response?.data ||
            "Cohorts cannot be updated right now. Please try again later."
        );
        setDisabled(false);
        setDisabledReason("");
      }
    },
    [user, customPeerGroupName, customPeerGroupTickersSelected, dispatch]
  );

  const currentCustomUserPeerGroups = useMemo(() => {
    if (cohortUpdateCount > 0) {
      return user?.customPeerGroups;
    }
  }, [user, cohortUpdateCount]);

  const [isAboutToDelete, setAboutToDelete] = useState(false);

  const handleDeleteCohort = useCallback(
    async (peerGroupKey) => {
      try {
        const newUserDetails = {
          ...user,
        };
        const currentCustomPeerGroups = user?.customPeerGroups;
        if (!currentCustomPeerGroups) {
          toast.error("No user cohorts to delete");
          return;
        } else {
          delete currentCustomPeerGroups[peerGroupKey];
          newUserDetails.customPeerGroups = currentCustomPeerGroups;
          const { status, data } = await UserService.updateUser(newUserDetails);
          if (status == 200) {
            await dispatch(updateUser(newUserDetails));
            toast.success(`${peerGroupKey} deleted`);
            await UserService.getUserInfo(localStorage.getItem("ACCESS_TOKEN"));
            setCohortUpdateCount((prev) => prev + 1);
            setCohortTableSelector("");
            setAboutToDelete(false);
          } else {
            toast.error(
              "Cohorts cannot be updated right now. Please try again later."
            );
            return;
          }
        }
      } catch (err) {
        // console.log(err);
        console.log(err?.response);
        toast.error(
          "Cohorts cannot be updated right now. Please try again later."
        );
        return;
      }
    },
    [user, dispatch]
  );

  useEffect(() => {
    if (peerGroupKey === "MetricsCohort" || viewPeerGroupMetricsComparison) {
      setPrintPDFasMultiplePages(true);
    } else {
      setPrintPDFasMultiplePages(false);
    }
  }, [
    peerGroupKey,
    viewPeerGroupMetricsComparison,
    setPrintPDFasMultiplePages,
  ]);

  useEffect(() => {
    if (defaultPeerGroupOptions.includes(customPeerGroupName)) {
      setDisabled(true);
      setDisabledReason(
        "Peer group name is reserved for default options. Please use a different custom peer group name."
      );
    } else if (
      currentCustomUserPeerGroups &&
      Object.keys(currentCustomUserPeerGroups).length &&
      Object.keys(currentCustomUserPeerGroups).some(
        (name) => name === customPeerGroupName
      )
    ) {
      setDisabled(true);
      setDisabledReason(
        "Peer group name already exists. Please use a different custom peer group name."
      );
    } else {
      setDisabled(false);
      setDisabledReason("");
    }
  }, [currentCustomUserPeerGroups, customPeerGroupName, cohortUpdateCount]);

  const [noActiveFilters, setNoActiveFilters] = useState(true);

  const peerGroupIsUnique = useMemo(() => {
    return (
      currentCompanyPeerGroup.some(
        (item) => !customPeerGroupTickersSelected.includes(item)
      ) ||
      customPeerGroupTickersSelected?.some(
        (item) => !currentCompanyPeerGroup?.includes(item)
      )
    );
  }, [currentCompanyPeerGroup, customPeerGroupTickersSelected]);

  const SavePeerGroup = () => {
    if (peerGroupIsUnique) {
      return (
        <div style={{ margin: 15 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginBottom: 10,
            }}
          >
            <span style={{ paddingRight: 10 }}>
              Add as New Custom Peer Group:
            </span>
            <Input
              style={{ width: 200, marginRight: 10 }}
              onChange={handleCustomPeerGroupNameChange}
              placeholder="Custom Peer Group 1"
              value={customPeerGroupName}
            />
            {disabled ? (
              <Tooltip title={disabledReason}>
                <div>
                  <Button
                    disabled={disabled}
                    onClick={(e) => handleSaveCohortSubmit(e)}
                  >
                    Save as New Peer Group
                  </Button>
                </div>
              </Tooltip>
            ) : (
              <Button
                disabled={disabled}
                onClick={(e) => handleSaveCohortSubmit(e)}
              >
                Save as New Peer Group
              </Button>
            )}
          </div>
        </div>
      );
    }
    return <></>;
  };

  const peerGroupTickers = useMemo(() => {
    return updatedTableTickers.includes(companyInfo.Ticker)
      ? updatedTableTickers
      : [...updatedTableTickers, companyInfo.Ticker];
  }, [companyInfo, updatedTableTickers]);

  const { weeklyReturns } = useSelector((state) => state.CompanyReducer);
  const [trailingVolatilityWindow, setTrailingVolatilityWindow] =
    useState("1yr");

  const [customEndDate, setCustomEndDate] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("ACCESS_TOKEN");
    if (peerGroupTickers && peerGroupTickers.length) {
      dispatch(getWeeklyReturns(peerGroupTickers, token));
    }
  }, [peerGroupTickers, dispatch]);

  const getStandardDeviation = (array) => {
    if (!array.length) return;
    const n = array.length;
    const mean = array.reduce((a, b) => a + b) / n;
    return Math.sqrt(
      array.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / (n - 1)
    );
  };

  const getAverage = (array) => array.reduce((a, b) => a + b, 0) / array.length;

  const dataExists = (data) => {
    return (
      data !== "#N/A" && !isNaN(parseFloat(data)) && parseFloat(data) >= -1
    );
  };
  const [lookbackIntervalsSelected, setLookbackIntervalsSelected] = useState([
    "6mo",
    "1yr",
    "2yr",
    "3yr",
  ]);
  const trailingVolatilityWindowWeeks = useMemo(() => {
    return trailingVolatilityWindow.split("yr")[0] * 52;
  }, [trailingVolatilityWindow]);

  const getAllWeeklyReturnData = useCallback(
    (ticker) => {
      const weeklyReturnsForCompany = weeklyReturns?.[ticker];
      if (!weeklyReturnsForCompany) return [];

      const formattedAllWeeklyReturns = Object.entries(weeklyReturnsForCompany)
        .filter(([key, val]) => key.includes("000Z") && !isNaN(parseFloat(val)))
        .map(([key, val], index) => ({
          date: key.split("T0")[0],
          "Weekly Return": val,
          key: index,
        }))
        .sort((a, b) => new Date(a.date) - new Date(b.date));

      return formattedAllWeeklyReturns;
    },
    [weeklyReturns]
  );

  const peerGroupVolatilities = useMemo(() => {
    const allLookBackRetuns = {};

    for (const ticker of peerGroupTickers) {
      const allWeeklyReturnData = getAllWeeklyReturnData(ticker);
      const getWeeklyReturnData = () => {
        if (
          !allWeeklyReturnData.indexOf(
            allWeeklyReturnData.find((item) => item["date"] === customEndDate)
          ) ||
          !allWeeklyReturnData?.[
            allWeeklyReturnData.indexOf(
              allWeeklyReturnData.find((item) => item["date"] === customEndDate)
            ) + 1
          ]
        ) {
          return allWeeklyReturnData;
        }
        return allWeeklyReturnData.slice(
          0,
          customEndDate
            ? allWeeklyReturnData.indexOf(
                allWeeklyReturnData.find(
                  (item) => item["date"] === customEndDate
                )
              ) + 1
            : undefined
        );
      };

      const weeklyReturnData = getWeeklyReturnData();
      if (!weeklyReturnData) return [];

      const sortedWeeklyReturnData = [...weeklyReturnData].reverse();
      const weeklyVolatilityData = [];

      for (const weeklyReturn of sortedWeeklyReturnData) {
        const futureValueExists = sortedWeeklyReturnData
          .slice(sortedWeeklyReturnData.indexOf(weeklyReturn))
          .some((d) => dataExists(d["Weekly Return"]));
        if (!futureValueExists) {
          return weeklyVolatilityData;
        }

        const indexOfWeeklyReturn =
          sortedWeeklyReturnData.indexOf(weeklyReturn);
        if (
          !(
            sortedWeeklyReturnData?.[
              indexOfWeeklyReturn + trailingVolatilityWindowWeeks
            ]?.["Weekly Return"] >= -1
          )
        ) {
          weeklyVolatilityData.push({
            Date: weeklyReturn.date,
            "Weekly Return": weeklyReturn["Weekly Return"],
            "Trailing Weekly Volatility": "",
          });
          continue;
        }
        const weeklyReturnsForPeriodSelectedWithoutDates =
          sortedWeeklyReturnData
            .slice(
              indexOfWeeklyReturn,
              indexOfWeeklyReturn + trailingVolatilityWindowWeeks
            )
            .map((item) => item?.["Weekly Return"]);
        const standardDeviationForRemainingWeeks = getStandardDeviation(
          weeklyReturnsForPeriodSelectedWithoutDates
        );
        const weeklyVolatility =
          standardDeviationForRemainingWeeks * Math.sqrt(52);

        if (!isNaN(parseFloat(weeklyVolatility))) {
          weeklyVolatilityData.push({
            Date: weeklyReturn.date,
            "Weekly Return": weeklyReturn["Weekly Return"],
            "Trailing Weekly Volatility": !isNaN(parseFloat(weeklyVolatility))
              ? weeklyVolatility * 100
              : "",
          });
        }
      }

      const lookBackIntervalsOptions = [
        { label: "6 Months", value: "6mo" },
        { label: "1 Year", value: "1yr" },
        { label: "2 Years", value: "2yr" },
        { label: "3 Years", value: "3yr" },
        { label: "5 Years", value: "5yr" },
        { label: "10 Years", value: "10yr" },
      ];
      const lookbackIntervalWeeks = lookBackIntervalsOptions.map(
        (intervalLabelAndValue) => {
          const interval = intervalLabelAndValue.value;
          return interval.includes("mo")
            ? interval.split("mo")[0] * (52 / 12)
            : interval.split("yr")[0] * 52;
        }
      );
      const volatilityLookbackData = lookBackIntervalsOptions.map(
        (intervalLabelAndValue, index) => {
          const interval = intervalLabelAndValue.value;
          const volatilityLookBack =
            getStandardDeviation(
              [...weeklyVolatilityData]
                .slice(0, lookbackIntervalWeeks[index])
                .map((item) => item?.["Weekly Return"])
            ) * Math.sqrt(52); //TODO: 52 or lookbackIntervalWeeks?
          return {
            "Use in Blended Average?":
              lookbackIntervalsSelected.includes(interval),
            "Lookback Interval": interval,
            "Lookback Volatility": volatilityLookBack * 100,
            key: interval,
          };
        }
      );

      const numericalVolatilitiesAvailable = volatilityLookbackData
        .filter((item) =>
          lookbackIntervalsSelected.includes(item["Lookback Interval"])
        )
        .map((item) => item["Lookback Volatility"])
        .filter((item) => !isNaN(parseFloat(item)));
      const lookBackBlendedAverage = getAverage(numericalVolatilitiesAvailable);

      allLookBackRetuns[ticker] = lookBackBlendedAverage;
    }
    return allLookBackRetuns;
  }, [
    peerGroupTickers,
    customEndDate,
    lookbackIntervalsSelected,
    trailingVolatilityWindowWeeks,
    getAllWeeklyReturnData,
  ]);

  const getData = useCallback(
    (metric, targetCompanies = []) => {
      const data = [];
      let comparisonTickers = [];
      if (targetCompanies.length) {
        comparisonTickers = targetCompanies;
      } else {
        comparisonTickers = peerGroupTickers;
      }

      const getMetricValue = (metric, ticker) => {
        if (metric?.isVolatility) {
          const volatility = peerGroupVolatilities?.[ticker] || null;
          return volatility;
        }
        if (metric?.isMeasure) {
          return measureOptions?.[metric.dataKey]?.getValue(
            companies?.[ticker],
            year
          );
        }
        return companies?.[ticker]?.[metric.dataKey];
      };

      // Gets all possible values to set headers of the CSV with "allValidDatapoints"
      const allComparisonMeasureValues = peerGroupTickers.map((ticker) => ({
        ticker,
        value: parseFloat(getMetricValue(metric, ticker)),
        stringValue: metric?.isStringValue
          ? getMetricValue(metric, ticker)
          : null,
      }));

      for (const ticker of comparisonTickers) {
        const measureValue = getMetricValue(metric, ticker);

        if (measureValue) {
          const allValidDatapoints = allComparisonMeasureValues.filter(
            (item) =>
              item.stringValue ||
              (!isNaN(item.value) &&
                item.value !== Infinity &&
                item.value !== -Infinity)
          );

          const percentileValue = !metric?.isStringValue
            ? getPercentileFromValue(
                allValidDatapoints.map((item) => item.value),
                parseFloat(measureValue),
                metric.dataKey
              )
            : null;

          const sameDatapoints = allValidDatapoints.filter(
            (item) => item.value === measureValue
          );

          const dataPoint = {
            measureValue: metric?.isStringValue
              ? measureValue
              : parseFloat(measureValue),
            isStringValue: metric?.isStringValue || false,
            index: 1,
            size:
              ticker === companyInfo?.Ticker
                ? 600
                : sameDatapoints.length > 1
                ? 150 * Math.min(sameDatapoints.length, 3)
                : 150,
            ticker: ticker,
            multipleTickers:
              sameDatapoints.length > 1
                ? sameDatapoints.map((item) => item.ticker)
                : [],
            companyName: companies?.[ticker]?.Company,
            measureType: metric.title,
            type: metric.type,
            isCurrent: metric.isCurrent,
            percentile: percentileValue,
            isTargetCompany: ticker === companyInfo.Ticker,
          };
          data.push(dataPoint);
        }
      }
      return data;
    },
    [companies, companyInfo, peerGroupTickers, peerGroupVolatilities, year]
  );

  const [includeMetricsInCSV, setIncludeMetricsInCSV] = useState(true);
  const [includeCurrentCompanyInCSV, setIncludeCurrentCompanyInCSV] =
    useState(true);

  const csvExportTickers = useMemo(() => {
    if (includeCurrentCompanyInCSV) {
      const filteredPeerGroupTickersSelected =
        customPeerGroupTickersSelected.filter(
          (item) => item !== companyInfo.Ticker
        );
      return [companyInfo.Ticker, ...filteredPeerGroupTickersSelected];
    } else {
      return customPeerGroupTickersSelected;
    }
  }, [
    companyInfo.Ticker,
    customPeerGroupTickersSelected,
    includeCurrentCompanyInCSV,
  ]);

  const PeerGroupTableWithOptions = ({ isSeparatePagePDF = false }) => (
    <>
      <CohortSelector
        companyInfo={companyInfo}
        setCohortTableTickers={() => null}
        cohortTableSelector={overlappingCohortTableSelector}
        setCohortTableSelector={setOverlappingCohortTableSelector}
        removeCustomCohort={true}
        removeAllCustomGroups={true}
        onlyIncludePeerGroups={true}
        heading="View Peer Group Overlap:"
        additionalOptions={["None"]}
        hidden={
          peerGroupKey !== "MetricsCohort" && !isCustomPeerGroup(peerGroupKey)
        }
      />
      {((peerGroupKey !== "MetricsCohort" &&
        !isCustomPeerGroup(peerGroupKey)) ||
        overlappingCohortTableSelector !== "None") && (
        <div style={{ width: "100%", display: "flex", marginTop: 10 }}>
          <h3
            style={{
              fontSize: 20,
              marginRight: 10,
            }}
          >
            {`${
              peerGroupKey !== "MetricsCohort" &&
              !isCustomPeerGroup(peerGroupKey)
                ? `Include`
                : "Add Additional"
            } Peers:`
              .split(" ")
              .map((head, index) => (
                <span key={index}>{head}&nbsp;</span>
              ))}
          </h3>
          <Checkbox.Group
            options={alternatePeerGroupOptions}
            defaultValue={[]}
            onChange={onAlternatePeerGroupChange}
            value={alternatePeerGroupsSelected}
          />
        </div>
      )}

      <div id="peer-group-table">
        {peerGroupKey === "MetricsCohort" &&
        peerGroupTableData.length === 0 &&
        noActiveFilters ? (
          <h4
            style={{
              fontWeight: "bold",
              textAlign: "center",
              paddingTop: 20,
            }}
          >
            Add "Peer Group Filters" above to create a peer group.
          </h4>
        ) : (
          <div style={{ padding: 10 }}>
            <Table
              columns={peerGroupTableColumns}
              dataSource={peerGroupTableData}
              size={"small"}
              pagination={{ pageSize: 50, hideOnSinglePage: true }}
            />
          </div>
        )}
      </div>
      <div style={{ paddingLeft: 40, paddingRight: 10 }}>
        {isCustomPeerGroup(peerGroupKey) ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginBottom: 10,
            }}
          >
            <span style={{ marginRight: 10 }}>Delete {peerGroupKey}:</span>
            <Button
              onClick={() =>
                isAboutToDelete
                  ? handleDeleteCohort(peerGroupKey)
                  : setAboutToDelete(true)
              }
              style={{
                backgroundColor: isAboutToDelete ? "var(--red)" : "",
              }}
            >
              {isAboutToDelete ? (
                <span
                  style={{
                    color: "var(--secondary)",
                    fontWeight: "bold",
                  }}
                >
                  Confirm Delete Peer Group?
                </span>
              ) : (
                "Delete Peer Group"
              )}
            </Button>
            {isAboutToDelete && (
              <div
                style={{ color: "var(--link-blue)", cursor: "pointer" }}
                onClick={() => setAboutToDelete(false)}
              >
                &nbsp;Don't delete peer group
              </div>
            )}
          </div>
        ) : (
          <span></span>
        )}
        {(currentCompanyPeerGroup.some(
          (item) => !customPeerGroupTickersSelected.includes(item)
        ) ||
          customPeerGroupTickersSelected?.some(
            (item) => !currentCompanyPeerGroup?.includes(item)
          )) && <></>}
      </div>
      <Tooltip
        style={{ textAlign: "center" }}
        title={
          !customPeerGroupTickersSelected.length
            ? "Select at least one company to export as CSV"
            : ""
        }
      >
        <Button
          style={{ marginLeft: 37.5, width: 250 }}
          disabled={customPeerGroupTickersSelected.length === 0}
          onClick={() => {
            const allMetricsForAllPeers = metricsToCompare.map((metric) =>
              getData(metric)
            );
            return exportToCSV(
              csvExportTickers.map((ticker) => {
                const companyData = {
                  Ticker: ticker,
                  "Company Name": `"${companies?.[ticker]?.Company || ""}"`,
                };
                if (!includeMetricsInCSV) {
                  return companyData;
                }

                allMetricsForAllPeers.forEach((metricDataForAllPeers) => {
                  const metricData = metricDataForAllPeers.find(
                    (peerMetricData) => peerMetricData.ticker === ticker
                  );
                  const metricDataDetails = metricDataForAllPeers.find(
                    (peerMetricData) =>
                      (peerMetricData.isStringValue &&
                        peerMetricData.measureValue) ||
                      !isNaN(peerMetricData.measureValue)
                  );
                  if (metricDataDetails) {
                    companyData[
                      (!metricDataDetails?.isCurrent ? `${year} ` : "") +
                        metricDataDetails.measureType
                    ] =
                      metricData &&
                      ((metricData.isStringValue && metricData.measureValue) ||
                        !isNaN(metricData.measureValue))
                        ? metricData?.measureValue
                        : "";
                  }
                });
                return companyData;
              }),

              `${companyInfo?.Ticker} ${peerGroupKey} ${
                new Date().toString().split("GMT-")[0]
              }.csv`
            );
          }}
        >
          Export Selected Companies as CSV
        </Button>
      </Tooltip>
      {customPeerGroupTickersSelected.length !== 0 && (
        <div>
          <Checkbox
            style={{ marginLeft: 37.5, marginTop: 5 }}
            checked={includeCurrentCompanyInCSV}
            onChange={() =>
              setIncludeCurrentCompanyInCSV(!includeCurrentCompanyInCSV)
            }
          >
            Include Current Company ({companyInfo.Ticker}) in CSV Export
          </Checkbox>
        </div>
      )}
      {customPeerGroupTickersSelected.length !== 0 && (
        <div>
          <Checkbox
            style={{ marginLeft: 37.5, marginTop: 5 }}
            checked={includeMetricsInCSV}
            onChange={() => setIncludeMetricsInCSV(!includeMetricsInCSV)}
          >
            Include Metrics in CSV Export
          </Checkbox>
        </div>
      )}
    </>
  );

  // const PeerGroupTableWithOptions = () => <></>;

  const additionalOptions = useMemo(() => {
    if (
      currentCustomUserPeerGroups &&
      Object.keys(currentCustomUserPeerGroups).length > 0
    ) {
      return [
        ...Object.keys(currentCustomUserPeerGroups),
        "Create Peer Group With Metrics",
      ];
    }
    return ["Create Peer Group With Metrics"];
  }, [currentCustomUserPeerGroups]);

  // Resets filter if going between sub-pages, but will keep values if not conditially rendered
  useMemo(() => {
    if (cohortTableSelector === "Create Peer Group With Metrics") {
      setFilter(JSON.parse(JSON.stringify(initFilter)));
    }
  }, [cohortTableSelector]);

  // To make it clear that peer metrics comparison is open while creating peer group by metrics:
  const scrollToRef = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  // Disable peer group comparison when switching to peer group by metrics
  const [switchedPeerGroupComparisonOff, setSwitchedPeerGroupComparisonOff] =
    useState(false);
  useEffect(() => {
    if (peerGroupKey === "MetricsCohort" && !switchedPeerGroupComparisonOff) {
      setViewPeerGroupMetricsComparison(false);
      setSwitchedPeerGroupComparisonOff((prev) => !prev);
    }
  }, [peerGroupKey, switchedPeerGroupComparisonOff]);
  useEffect(() => {
    if (peerGroupKey !== "MetricsCohort") {
      setSwitchedPeerGroupComparisonOff(false);
    }
  }, [peerGroupKey]);

  // Scroll to the peer group metrics comparison once "Show metrics comparison is clicked"
  const peerGroupMetricsRef = useRef(null);

  const [highlightSection, setHighlightSection] = useState(false);
  const [alreadyScrolled, setAlreadyScrolled] = useState(false);

  useEffect(() => {
    if (!peerGroupMetricsRef) return;

    if (!viewPeerGroupMetricsComparison) {
      setAlreadyScrolled(false);
    }

    if (viewPeerGroupMetricsComparison && peerGroupKey !== "MetricsCohort") {
      setAlreadyScrolled(true);
    }

    if (
      viewPeerGroupMetricsComparison &&
      peerGroupMetricsRef &&
      peerGroupKey === "MetricsCohort" &&
      !alreadyScrolled
    ) {
      scrollToRef(peerGroupMetricsRef);
      setAlreadyScrolled(true);
      setHighlightSection(true);
      setTimeout(function () {
        setHighlightSection(false);
      }, 1250);
    }
  }, [viewPeerGroupMetricsComparison, peerGroupKey, alreadyScrolled]);

  useEffect(() => {
    setCohortUpdateCount((prev) => prev + 1);
  }, [companyInfo]);

  return (
    <>
      {!isTier2 && !peerGroupAccess ? (
        <div
          className="comp-ai-section col-lg-9 executives "
          style={{ height: "100%", padding: 50 }}
        >
          <h3
            style={{
              textAlign: "center",
              fontWeight: "normal",
              fontSize: 22,
            }}
          >
            <p style={{ fontWeight: "bold" }}>
              This page is for C-Suite Comp&reg; Enterprise users only.
            </p>
            <br />
            <p>
              Contact your account manager to access to Peer Group Analysis
              where you can form your own compensation or performance peer
              groups for benchmarking and save up to 75 unique peer groups in
              your account for future reference.
            </p>
          </h3>
        </div>
      ) : (
        <>
          <div className="row" id="main-component">
            {companyInfoComponent}
            <div className="company-calculator-section col-lg-9 executives p-0">
              <h3 style={{ textAlign: "center" }}>Peer Group Analysis</h3>
              <CohortSelector
                companyInfo={companyInfo}
                setCohortTableTickers={setCohortTableTickers}
                cohortTableSelector={cohortTableSelector}
                setCohortTableSelector={setCohortTableSelector}
                removeCustomCohort={true}
                onlyIncludePeerGroups={true}
                heading="Peer Group Type:"
                additionalOptions={additionalOptions}
                key={cohortUpdateCount}
              />
              {!PDFprocessing && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: 10,
                  }}
                >
                  <Button
                    onClick={() =>
                      setViewPeerGroupMetricsComparison(
                        !viewPeerGroupMetricsComparison
                      )
                    }
                  >
                    {!viewPeerGroupMetricsComparison ? "Show" : "Hide"} Peer
                    Group Metrics Comparisons
                  </Button>
                </div>
              )}

              {peerGroupKey === "MetricsCohort" && !PDFprocessing && (
                <PeerGroupsByMetricsTable
                  setCustomCohortTickers={setCustomCohortTickers}
                  setNoActiveFilters={setNoActiveFilters}
                  filter={filter}
                  setFilter={setFilter}
                />
              )}

              {viewPeerGroupMetricsComparison && (
                <div
                  ref={peerGroupMetricsRef}
                  style={{
                    backgroundColor: highlightSection ? "lightblue" : "",
                    transition: "background-color 1000ms linear",
                  }}
                >
                  <PeerGroupMetricsComparison
                    companyInfo={companyInfo}
                    updatedTableTickers={updatedTableTickers}
                    PDFprocessing={PDFprocessing}
                    getData={getData}
                    year={year}
                    setYear={setYear}
                  />
                </div>
              )}

              <div
                style={{
                  height: printPDFasMultiplePages && PDFprocessing ? 300 : 0,
                }}
              ></div>
              {!viewPeerGroupMetricsComparison && <PeerGroupTableWithOptions />}
              {!viewPeerGroupMetricsComparison && SavePeerGroup()}
            </div>
          </div>
          {viewPeerGroupMetricsComparison && (
            <PeerGroupTableWithOptions isSeparatePagePDF={true} />
          )}
        </>
      )}
      {viewPeerGroupMetricsComparison && SavePeerGroup()}
    </>
  );
}
