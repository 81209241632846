import { DEFAULT_YEAR } from "../Constants/systemSetting.js";
import { maxExecutiveRanks } from "../Constants/executiveMaxRank.js";

export default function generateInitFilter() {
  return {
    currentYear: DEFAULT_YEAR,
    salary: {
      salaryMin: "",
      salaryMax: "",
    },
    age: {
      ageMin: 0,
      ageMax: 100,
    },
    tenure: {
      tenureMin: 0,
      tenureMax: 100,
    },
    yearsAtCompany: {
      yearsAtCompanyMin: 0,
      yearsAtCompanyMax: 100,
    },
    executiveRank: {
      executiveRankMin: 1,
      executiveRankMax: maxExecutiveRanks?.[DEFAULT_YEAR] || 8,
    },
    percentile: {
      percentileMin: 0,
      percentileMax: 100,
    },
    payRatio: {
      payRatioMin: 0,
      payRatioMax: (1e5).toLocaleString(),
    },
    committees: [],
    majors: [],
    universities: [],
    degrees: [],
    qualifications: [],
    industry: [],
    SICs: [],
    sector: [],
    company: [],
    cohortFilter: "analyst",
    cohort: [],
    executives: [],
    companyRank: {
      companyRankMin: null,
      companyRankMax: null,
    },
    marketCap: {
      marketCapMin: "",
      marketCapMax: "",
      minDigit: 1e3,
      maxDigit: 1e3,
    },
    revenue: {
      revenueMin: "",
      revenueMax: "",
      minDigit: 1e3,
      maxDigit: 1e3,
    },
    assets: {
      assetsMin: "",
      assetsMax: "",
      minDigit: 1e3,
      maxDigit: 1e3,
    },
    ROA: {
      ROAMin: "",
      ROAMax: "",
    },
    ROE: {
      ROEMin: "",
      ROEMax: "",
    },
    grossProfit: {
      grossProfitMin: "",
      grossProfitMax: "",
    },
    OCF_Assets: {
      OCF_AssetsMin: "",
      OCF_AssetsMax: "",
    },
    revenueGrowthPercent: {
      revenueGrowthPercentMin: "",
      revenueGrowthPercentMax: "",
    },
    netIncome: {
      netIncomeMin: "",
      netIncomeMax: "",
      minDigit: 1e3,
      maxDigit: 1e3,
    },
    employees: {
      employeesMin: "",
      employeesMax: "",
    },
    locationFilter: "company",
    companyState: [],
    companyCity: [],
    executiveState: [],
    executiveCity: [],
    indexSelected: "All",
    index: [],
    founders: "all",
    diffPercentAllowed: 0,
  };
}
